/* eslint-disable import/prefer-default-export */

export const Endpoints = {
  api: "/api",
  login: "/api/auth/login",
  introspection: "/api/auth/introspection",
  companies: {
    base: "/api/companies",
    names: "/api/companies/names",
    company: (uuid: string) => `/api/companies/${uuid}`,
    companyShort: (uuid: string) => `/api/companies/${uuid}/short`,
    companyLogo: (uuid: string) => `/api/companies/${uuid}/logo`,
    forms: (uuid: string) => `/api/companies/${uuid}/documents`,
    duerp: (uuid: string) => `/api/companies/${uuid}/duerps`,
    complementaryForms: (uuid: string) => `/api/companies/${uuid}/complementaryForms`
  },
  forms: "/api/forms/documents",
  users: {
    base: "/api/users",
    changePasswordRequest: "/api/users/recover-password/email",
    changePassword: "/api/users/recover-password",
    companyUsers: {
      base: "/api/users/company-users",
      register: "/api/users/company-users/register",
      companies: (uuid: string) => `/api/users/company-users/${uuid}/companies`,
      email: (uuid: string) => `/api/users/company-users/${uuid}/email`
    },
    expertUsers: {
      base: "/api/users/expert-users",
      register: "/api/users/expert-users/register",
      checkIsExpertUserByEmail: (email: string) => `api/users/expert-users/${email}/check`,
      companies: (uuid: string) => `/api/users/company-users/${uuid}/companies`
    }
  },
  duerpForms: {
    base: "/api/forms/duerps",
    getDuerp: (uuid: string) => `/api/forms/duerps/${uuid}`,
    getDuerpByReference: "/api/forms/duerps/reference",
    searchInDuerp: (uuid: string) => `/api/forms/duerps/${uuid}/searchIn`,
    exportDuerp: (uuid: string) => `/api/forms/duerps/${uuid}/export-duerp`,
    duplicateDuerp: (uuid: string) => `/api/forms/duerps/${uuid}/duplicate`,
    archiveDuerp: (uuid: string) => `/api/forms/duerps/${uuid}/archive`,
    actionPlanDuerp: (uuid: string) => `/api/forms/duerps/${uuid}/export-action-plan`,
    recommendationPlanDuerp: (uuid: string) => `/api/forms/duerps/${uuid}/export-recommendation-plan`,
    treeWithRef: (ref: string) => `${Endpoints.duerpForms.base}/references/${ref}/tree`,
    tree: (uuid: string) => `${Endpoints.duerpForms.base}/${uuid}/tree`,
    validate: (uuid: string) => `${Endpoints.duerpForms.base}/${uuid}/validate`,
    generalComment: (uuid: string) => `${Endpoints.duerpForms.base}/${uuid}/general-comment`,
    changeCustomRiskTitle: (uuid: string) => `${Endpoints.duerpForms.base}/custom-risk/${uuid}/new-title`,

    baseSection: (uuid: string, sectionUuid: string) => `${Endpoints.duerpForms.base}/${uuid}/sections/${sectionUuid}`,
    treeSection: (uuid: string, sectionUuid: string) =>
      `${Endpoints.duerpForms.base}/${uuid}/sections/${sectionUuid}/tree`,

    updateRiskOrdering: (duerpUuid: string) => `/api/forms/duerps/${duerpUuid}/sections/risk-ordering`,

    baseQuestion: (uuid: string, sectionUuid: string, questionUuid: string) =>
      `${Endpoints.duerpForms.base}/${uuid}/sections/${sectionUuid}/questions/${questionUuid}`,
    customQuestion: (uuid: string, sectionUuid: string) =>
      `${Endpoints.duerpForms.base}/${uuid}/sections/${sectionUuid}/questions`,
    customQuestionOira: (uuid: string, sectionUuid: string, oiraQuestionUuid: string) =>
      `${Endpoints.duerpForms.base}/${uuid}/sections/${sectionUuid}/questions/${oiraQuestionUuid}`,
    customQuestionsOiraFromSection: (uuid: string, sectionUuid: string, oiraSectionUuid: string) =>
      `${Endpoints.duerpForms.base}/${uuid}/sections/${sectionUuid}/questions/fromSection/${oiraSectionUuid}`
  },
  complementaryForms: {
    base: "/api/forms/complementary-forms",
    template: "/api/forms/complementary-forms/template",
    getComplementaryForm: (uuid: string) => `/api/forms/complementary-forms/${uuid}`,
    archiveComplementaryForm: (uuid: string) => `/api/forms/complementary-forms/${uuid}/archive`,
    finalizeComplementaryForm: (uuid: string) => `/api/forms/complementary-forms/${uuid}/finalize`,
    getFutureMeasuresByPreventer: (qcUuid: string) => `/api/forms/complementary-forms/${qcUuid}/future-measures`,
    completeComplementaryForm: (uuid: string) => `/api/forms/complementary-forms/${uuid}/complete`,
    duplicateComplementaryForm: (uuid: string) => `/api/forms/complementary-forms/${uuid}/duplicate`,
    downloadComplementaryForm: (uuid: string) => `/api/forms/complementary-forms/${uuid}/export-complementary-form`,
    associateDuerpsToComplementaryForm: (uuid: string) => `/api/forms/complementary-forms/${uuid}/duerps`
  },
  oira: {
    base: "/api/forms/oira",
    singleOiraByGroup: "/api/forms/oira/unique",
    getOira: (uuid: string) => `/api/forms/oira/${uuid}`,
    getOiraGroup: (groupUuid: string) => `/api/forms/oira/groups/${groupUuid}`,
    publishOiraVersion: (uuid: string) => `/api/forms/oira/${uuid}/publish`,
    unpublishOira: (groupUuid: string) => `/api/forms/oira/groups/${groupUuid}/unpublish`,
    importOiraForm: "/api/forms/oira/import",
    importOiraInGroup: (groupUuid: string) => `/api/forms/oira/groups/${groupUuid}/import`,
    updateSectionDescription: (oiraUuid: string, sectionUuid: string) =>
      `/api/forms/oira/${oiraUuid}/sections/${sectionUuid}`,
    updateQuestionDescription: (oiraUuid: string, questionUuid: string) =>
      `/api/forms/oira/${oiraUuid}/questions/${questionUuid}`,
    downloadOiraForm: (oiraUuid: string) => `/api/forms/oira/${oiraUuid}/risk-list`,
    getOiraSimple: `/api/forms/oira/simple`,
    getOiraSectionSimple: (oiraUuid: string) => `/api/forms/oira/${oiraUuid}/simple`,
    getOiraSectionRiskSimple: (sectionUuid: string) => `/api/forms/oira/sections/${sectionUuid}/simple`
  },
  organizations: {
    base: "/api/organizations",
    organization: (uuid: string) => `/api/organizations/${uuid}`,
    uniqueOrganization: () => `/api/organizations/unique`,
    organizationLogo: (uuid: string) => `/api/organizations/${uuid}/logo`
  }
};
