import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ClickAwayListener, Drawer as MuiDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, useScrollTrigger, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { backOfficeUserAtom } from "../../../atoms/Atoms";
import { Role } from "../../../interfaces/User";
import colors from "../../../resources/cssConstant";
import { adminList, DrawerIcons, preventerList } from "../../../utils/DrawerList/DrawerLists";
import GenericIconButton from "../../Generics/buttons/GenericIconButton";

export default function Drawer() {
  const theme = useTheme();
  const location = useLocation();
  const user = useAtomValue(backOfficeUserAtom);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const drawerWidth = openDrawer ? 300 : (theme.components?.MuiDrawer?.styleOverrides?.paper as any)?.minWidth;
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 70 });

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (openDrawer) handleDrawerClose();
  }, [location]);

  const renderMenu = (list: DrawerIcons[]) => (
    <List>
      {list.map((item) => (
        <ListItem key={item.itemName} disablePadding>
          <ListItemButton component={NavLink} to={item.itemLink} selected={location.pathname === item.itemLink}>
            <ListItemIcon sx={{ p: 0 }}>{item.itemIcon}</ListItemIcon>
            <ListItemText primary={openDrawer ? item.itemName : null} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <MuiDrawer
        variant="permanent"
        open={openDrawer}
        anchor="left"
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            border: "none",
            flexShrink: 0,
            width: drawerWidth,
            paddingTop: trigger ? "0" : `${(theme.components?.MuiAppBar?.styleOverrides?.root as any)?.height}`,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.enteringScreen
            })
          }
        }}
      >
        <Stack direction="row" justifyContent={openDrawer ? "right" : "center"} width="100%" sx={{ p: 2 }}>
          <GenericIconButton
            onClick={openDrawer ? handleDrawerClose : handleDrawerOpen}
            icon={openDrawer ? faChevronLeft : faChevronRight}
            tooltip={openDrawer ? "Fermer le menu" : "Ouvrir le menu"}
            color={colors.white}
            size="sm"
          />
        </Stack>
        {renderMenu(user.role === Role.ADMIN ? adminList : preventerList)}
      </MuiDrawer>
    </ClickAwayListener>
  );
}
