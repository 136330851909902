import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { currentDuerpAtom } from "../../../atoms/Atoms";
import { ProgressStatus } from "../../../interfaces/Form";
import colors from "../../../resources/cssConstant";
import GenericCard from "../../Generics/GenericCard/GenericCard";

interface CommentCardProps {
  title: string;
  icon: IconDefinition;
  commentContent: string;
  editComment?: () => void;
  backgroundColor?: string;
}

export default function CommentCard({
  title,
  icon,
  editComment,
  commentContent,
  backgroundColor = colors.white
}: Readonly<CommentCardProps>) {
  const [comment, setComment] = useState<string>(commentContent);
  const duerp = useAtomValue(currentDuerpAtom);
  const disabled = duerp.progressStatus !== ProgressStatus.NOT_FINALIZED;

  useEffect(() => {
    setComment(commentContent);
  }, [commentContent]);
  return (
    <GenericCard title={title} icon={icon} editCard={editComment} backgroundColor={backgroundColor} disabled={disabled}>
      {comment ? (
        <Typography variant="body1">{comment}</Typography>
      ) : (
         <Typography variant="body1" sx={{ fontStyle: "italic" }}>
           Aucune remarque ajoutée
         </Typography>
       )}
    </GenericCard>
  );
}
