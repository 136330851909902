import { faCirclePlus, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { userShortAtom } from "../../../../atoms/Atoms";
import { ICompany, ICompanyShort } from "../../../../interfaces/Company";
import { IDuerpCreation } from "../../../../interfaces/DuerpForm";
import { AutocompleteOption, IShape } from "../../../../interfaces/Filter";
import { Type } from "../../../../interfaces/Form";
import { IOiraShortForm } from "../../../../interfaces/OiraForm";
import { Role } from "../../../../interfaces/User";
import colors from "../../../../resources/cssConstant";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../../resources/Routes";
import ComplementaryFormService from "../../../../services/ComplementaryFormService";
import DuerpService from "../../../../services/DuerpService";
import GenericButton from "../../../Generics/buttons/GenericButton";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import GenericAutoComplete from "../../../Generics/GenericAutoComplete/GenericAutoComplete";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";

interface NewDocumentButtonProps {
  documentType: Type;
  company: ICompanyShort | ICompany;
  buttonWithIcon: boolean;
  menuButton?: boolean;
  oiras: IOiraShortForm[];
}

const autocompleteSchema = yup.object<IShape<AutocompleteOption>>({
  id: yup.string(),
  name: yup.string()
});

export default function NewDocumentButton({
  documentType,
  company,
  buttonWithIcon,
  menuButton,
  oiras
}: Readonly<NewDocumentButtonProps>) {
  const navigate = useNavigate();
  const user = useAtomValue(userShortAtom);

  const [openNewDuerpModal, setOpenNewDuerpModal] = useState<boolean>(false);
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);

  const isDuerp = documentType === Type.DUERP;
  const typeLabel = isDuerp ? "DUERP" : "Questionnaire complémentaire";

  const { control: addNewDuerpControl, handleSubmit: addNewDuerpSubmit } = useForm({
    resolver: yupResolver(autocompleteSchema),
    mode: "onTouched"
  });

  const createNewComplementaryForm = async () => {
    const res = await ComplementaryFormService().createComplementaryForm(company?.uuid, []);
    if (res?.data) {
      const path =
        user.role === Role.COMPANY_USER ? frontOfficeRoutes.complementary_form : backOfficeRoutes.complementary_form;
      navigate(generatePath(path, { refNumber: res.data.reference.replaceAll("/", "%2F") }), {
        state: { company, uuid: res.data.uuid }
      });
    }
  };

  const handleNewDocument = async () => {
    if (isDuerp) {
      setOpenNewDuerpModal(true);
    } else {
      await createNewComplementaryForm();
    }
  };

  const handleAddDuerp = async (data: FieldValues) => {
    setLoadingAdd(true);
    const duerpCreationDto: IDuerpCreation = {
      companyUuid: company?.uuid ?? "",
      oiraFormUuid: data.duerpFormValue.id
    };
    const res = await DuerpService().createDuerp(duerpCreationDto);
    if (res.data) {
      const path = user.role === Role.COMPANY_USER ? frontOfficeRoutes.duerp : backOfficeRoutes.duerp;
      navigate(generatePath(path, { refNumber: res.data.reference.replaceAll("/", "%2F") }), {
        state: { company, uuid: res.data.uuid }
      });
    } else {
      toast.error("Impossible de créer de nouveau DUERP.");
    }
    setLoadingAdd(false);
  };

  const renderNewDuerpModal = () => (
    <GenericDialog
      openDialog={openNewDuerpModal}
      handleClose={() => setOpenNewDuerpModal(false)}
      title={`Nouveau DUERP pour ${company?.companyName}`}
      onValid={addNewDuerpSubmit(handleAddDuerp)}
      disabled={loadingAdd}
    >
      <GenericAutoComplete
        name="duerpFormValue"
        options={oiras}
        control={addNewDuerpControl}
        id="duerpFormValue"
        label="Sélectionnez un questionnaire"
      />
    </GenericDialog>
  );

  const renderMenuButton = () => (
    <MenuItem onClick={() => createNewComplementaryForm()}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faFile} size="xs" color={colors.primary} />
      </ListItemIcon>
      <Typography variant="body1">Nouveau questionnaire complémentaire</Typography>
    </MenuItem>
  );

  const renderButton = () =>
    menuButton ? (
      renderMenuButton()
    ) : (
      <GenericButton text={`Nouveau ${typeLabel}`} icon={faCirclePlus} onClick={() => handleNewDocument()} />
    );

  return (
    <>
      {renderNewDuerpModal()}
      {buttonWithIcon ? (
        <GenericIconButton onClick={() => handleNewDocument()} icon={faCirclePlus} tooltip={`Nouveau ${typeLabel}`} />
      ) : (
         renderButton()
       )}
    </>
  );
}
