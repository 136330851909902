import { faPencil, faSquareCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DragIndicator } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { IDuerpLightQuestionWithCertified } from "../../../../../interfaces/DuerpForm";
import { ProgressStatus } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import colors from "../../../../../resources/cssConstant";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import UpdateRiskTitleDialog from "./UpdateRiskTitleDialog";

interface DraggableRiskProps {
  risk: IDuerpLightQuestionWithCertified;
  index: number;
  moveRisk: (dragIndex: number, hoverIndex: number) => void;
  deleteRisk: (index: number) => void;
  updateRiskTitle: (updatedRisk: IDuerpLightQuestionWithCertified) => void;
  user: any;
  duerp: any;
}

export default function DraggableRisk({ risk, index, moveRisk, deleteRisk, updateRiskTitle, user, duerp }: Readonly<DraggableRiskProps>) {

  const ref = useRef<HTMLDivElement>(null);

  const [updateTitle, setUpdateTitle] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag({
    type: "RISK",
    canDrag: duerp.progressStatus === ProgressStatus.NOT_FINALIZED,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, drop] = useDrop({
    accept: "RISK",
    hover(item: { index: number }, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      moveRisk(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  drag(drop(ref));

  const handleTitleChange = (updatedRisk: IDuerpLightQuestionWithCertified) => updateRiskTitle(updatedRisk);

  const button = useMemo(() => {
    if (risk?.certified) {
      return <GenericIconButton
        size={"sm"}
        color={colors.primary}
        icon={faSquareCheck}
        onClick={() => {}}
        tooltip={"Risque importé depuis un OIRA"}
        disabledColor={colors.primary}
        disabled
      />;
    } else {
      return <GenericIconButton
        size={"sm"}
        color={colors.primary}
        icon={faPencil}
        onClick={() => setUpdateTitle(true)}
        tooltip={
          user.role === Role.COMPANY_USER && risk.addByExpert
          ? "Vous ne pouvez pas modifier un risque ajouté par un préventeur"
          : "Modifier le titre du risque"}
        disabled={
          (user.role === Role.COMPANY_USER && risk.addByExpert) || duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
        }
      />;
    }
  }, [risk?.certified]);

  return (
    <>
      <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        spacing={1}
        style={{ opacity: isDragging ? 0.5 : 1, cursor: "move" }}
      >
        <DragIndicator />
        <GenericIconButton
          size="sm"
          onClick={() => deleteRisk(index)}
          icon={faTrash}
          tooltip={
            user.role === Role.COMPANY_USER && risk.addByExpert
            ? "Vous ne pouvez pas supprimer un risque ajouté par un préventeur"
            : "Supprimer ce risque"
          }
          disabled={
            (user.role === Role.COMPANY_USER && risk.addByExpert) || duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
          }
          color={colors.deleteIcon}
        />
        {button}
        <Typography variant="body1">{risk.title}</Typography>
      </Stack>
      <UpdateRiskTitleDialog open={updateTitle} onClose={() => setUpdateTitle(false)} onValid={handleTitleChange} risk={risk} />
    </>
  )
    ;
}
