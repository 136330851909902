import { faLock, faLockOpen, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { allOrganizationsAtom, userAtom } from "../../../../atoms/Atoms";
import { ICollaborator, ICompanyShort } from "../../../../interfaces/Company";
import { IOptionValue, IShape } from "../../../../interfaces/Filter";
import { Role } from "../../../../interfaces/User";
import colors from "../../../../resources/cssConstant";
import { StatusCode } from "../../../../resources/StatusCode";
import CompanyService from "../../../../services/CompanyService";
import UserService from "../../../../services/UserService";
import { stringifyErrorMessage } from "../../../../utils/ConversionMethods";
import GenericButton from "../../../Generics/buttons/GenericButton";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";
import GenericTextField from "../../../Generics/GenericTextField/GenericTextField";

interface CreateCompanySchema {
  companyName: string;
  membershipOrganization: string;
  membershipNumber: string;
  logo: object;
  collaborators: ICollaborator[];
  updateBlocked: boolean;
}

interface AddCollaborator {
  collaborator: ICollaborator;
}

const addCollaboratorSchema = yup.object<IShape<AddCollaborator>>({
  collaborator: yup.string().min(3, "Le format est incorrect.").email("Le format est incorrect."),
});

const testImageFormat = (format: string) => ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(format);

interface CompanyFormProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  isUpdate?: boolean;
  company?: ICompanyShort;
  fetchCompanies: () => void;
}

export default function CompanyForm({
  openModal,
  setOpenModal,
  isUpdate,
  company,
  fetchCompanies,
}: Readonly<CompanyFormProps>) {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [checkEmailLoading, setCheckEmailLoading] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<Blob | null>(null);
  const [collaborators, setCollaborators] = useState<ICollaborator[]>([]);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);
  const [companyUpdateBlocked, setCompanyUpdateBlocked] = useState<boolean>(company?.updateBlocked ?? false);
  const organizations = useAtomValue(allOrganizationsAtom);
  const user = useAtomValue(userAtom);

  const userUpdateBlocked = useMemo(
    () => user.role === Role.COMPANY_USER && companyUpdateBlocked,
    [user.role, companyUpdateBlocked],
  );

  const options: IOptionValue[] = organizations.map((organization) => ({
    value: organization.organizationName,
    label: organization.organizationName,
  }));

  const checkFileType = (fileList: FileList) => {
    if (!fileList?.length) return true;
    return testImageFormat(fileList[0]?.type);
  };

  const checkFileSize = (fileList: FileList) => {
    if (!fileList?.length) return true;
    return fileList[0]?.size <= 2000000;
  };

  const updateErrorMessage = (fileList: FileList) => {
    if (!fileList || fileList.length === 0) return true;
    if (!checkFileSize(fileList)) {
      setFileErrorMessage("La taille du fichier ne doit pas dépasser 2Mo.");
      setCompanyLogo(null);
      return false;
    }
    if (!checkFileType(fileList)) {
      setFileErrorMessage("Seuls les formats suivants sont acceptés : jpeg, jpg, png, svg.");
      setCompanyLogo(null);
      return false;
    }
    setFileErrorMessage(null);
    setCompanyLogo(fileList[0]);
    return true;
  };

  const createCompanySchema = yup.object<IShape<CreateCompanySchema>>({
    updateBlocked: yup.boolean().default(company?.updateBlocked ?? false),
    companyName: yup
      .string()
      .max(70, "Le nom de l'entreprise ne doit pas excéder 70 caractères.")
      .when("updateBlocked", {
        is: true,
        then: (schema) => {
          return user.role === Role.COMPANY_USER ? schema.notRequired() : schema.required("Veuillez remplir ce champ.");
        },
        otherwise: (schema) => schema.required("Veuillez remplir ce champ."),
      }),
    membershipOrganization: yup.string().when("updateBlocked", {
      is: true,
      then: (schema) => {
        return user.role === Role.COMPANY_USER ? schema.notRequired() : schema.required("Veuillez remplir ce champ.");
      },
      otherwise: (schema) => schema.required("Veuillez remplir ce champ."),
    }),
    membershipNumber: yup.string().trim().max(70, "Le numéro d'adhérent SPSTI ne doit pas excéder 70 caractères."),
    logo: yup.mixed().test("fileTest", "Impossible d'ajouter ce fichier.", (value) => updateErrorMessage(value)),
    collaborators: yup.array(yup.string().email("Le format est invalide.")),
  });

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid: createValid },
  } = useForm({
    resolver: yupResolver(createCompanySchema),
    mode: "onTouched",
  });

  const {
    register: collaboratorRegister,
    handleSubmit: handleCollaboratorSubmit,
    reset: collaboratorReset,
    formState: { errors: collaboratorErrors, isValid: collaboratorValid },
  } = useForm({
    resolver: yupResolver(addCollaboratorSchema),
    mode: "onTouched",
  });

  const logo = watch("logo");

  const getCompanyLogo = async () => {
    if (company?.logoUuid && openModal) {
      const res = await CompanyService().getCompanyLogo(company.uuid);
      if (res.data) {
        setCompanyLogo(res.data);
      }
    }
  };

  useEffect(() => {
    setValue("updateBlocked", companyUpdateBlocked, { shouldDirty: true });
  }, [companyUpdateBlocked]);

  useEffect(() => {
    if (!isUpdate && user.role === Role.COMPANY_USER) {
      setCollaborators([user.email]);
    } else if (isUpdate && company) {
      setCollaborators(company.collaborators.concat(company.externalCollaborators));
    } else {
      setCollaborators([]);
    }
    setShowPreview(false);
    collaboratorReset();
    setFileErrorMessage(null);
    if (!openModal) setCompanyLogo(null);
    if (openModal) getCompanyLogo();
    reset();
  }, [openModal]);

  useEffect(() => {
    if (logo?.length > 0) setShowPreview(true);
  }, [logo]);

  const updateCompanyLogo = async (companyUuid: string) => {
    await CompanyService().updateCompanyLogo(companyUuid, logo[0]);
  };

  const onCompanySubmit = async (data: FieldValues) => {
    const companyToSubmit = {
      companyName: data.companyName,
      membershipOrganization: data.membershipOrganization,
      membershipNumber: data.membershipNumber,
      updateBlocked: data.updateBlocked,
      collaborators,
    };
    const response =
      isUpdate && company?.uuid
        ? await CompanyService().updateCompany(company?.uuid, companyToSubmit)
        : await CompanyService().createCompany(companyToSubmit);
    if (response.status === StatusCode.CREATED || response.status === StatusCode.OK) {
      if (logo?.length > 0) await updateCompanyLogo(response.data.uuid);
      toast.success(
        response.status === StatusCode.CREATED
          ? `L'entreprise ${data.companyName} a bien été créée.`
          : `L'entreprise ${data.companyName} a bien été modifiée.`,
      );
      setShowPreview(false);
      setOpenModal(false);
      setCollaborators([]);
      fetchCompanies();
      setFileErrorMessage(null);
      reset();
    } else if (response.status === StatusCode.CONFLICT) {
      toast.error("Le numéro d'adhérent spécifié n'est pas disponible.");
    } else toast.error("Une erreur est survenue.");
  };

  const sendUserInCompany = (data: FieldValues) => {
    const { collaborator } = data;
    if (collaborators.includes(collaborator)) {
      toast.error("Vous avez déjà ajouté cet utilisateur.");
    } else {
      setCheckEmailLoading(true);
      UserService()
        .checkIsExpertUserByEmail(collaborator)
        .then((response) => {
          if (response.data)
            toast.error(
              "Vous ne pouvez pas ajouter un préventeur ou un administrateur comme collaborateur de l'entreprise.",
            );
          else {
            setCollaborators([...collaborators, collaborator]);
            collaboratorReset();
          }
        })
        .finally(() => setCheckEmailLoading(false));
    }
  };
  const deleteCollaborator = (email: string) => {
    setCollaborators(collaborators.filter((collaborator) => collaborator !== email));
  };

  const renderLockUpdate = useMemo(() => {
    if (companyUpdateBlocked) {
      return (
        <Stack spacing={1} direction={"row"}>
          <GenericIconButton
            icon={faLock}
            tooltip={"Déverrouiller la modification"}
            color={colors.deleteIcon}
            onClick={() => setCompanyUpdateBlocked(false)}
            size="sm"
            disabled={userUpdateBlocked}
          />
          <Typography variant="body1" alignContent={"center"}>
            {"Déverrouiller la modification "}
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={1} direction={"row"}>
          <GenericIconButton
            icon={faLockOpen}
            tooltip={"Verrouiller la modification"}
            color={colors.primary}
            onClick={() => setCompanyUpdateBlocked(true)}
            size="sm"
            disabled={userUpdateBlocked}
          />
          <Typography variant="body1" alignContent={"center"}>
            {"Verrouiller la modification "}
          </Typography>
        </Stack>
      );
    }
  }, [companyUpdateBlocked]);

  const renderCompanyForm = () => (
    <Stack spacing={5}>
      <Stack component="form" justifyContent="center" spacing={5}>
        <GenericTextField
          defaultValue={company?.companyName ?? ""}
          id="companyName"
          label="Raison sociale de l'entreprise"
          required
          error={!!errors.companyName}
          helperText={stringifyErrorMessage(errors.companyName)}
          register={register("companyName")}
          disabled={userUpdateBlocked}
        />
        <GenericTextField
          required
          defaultValue={company?.membershipOrganization ?? ""}
          id="membershipOrganization"
          label="Votre service de prévention et de santé au travail"
          select
          optionValues={options}
          error={!!errors.membershipOrganization}
          helperText={stringifyErrorMessage(errors.membershipOrganization)}
          register={register("membershipOrganization")}
          disabled={userUpdateBlocked}
        />
        <GenericTextField
          defaultValue={company?.membershipNumber ?? ""}
          id="membershipNumber"
          label="Numéro d'adhérent SPSTI"
          error={!!errors.membershipNumber}
          helperText={stringifyErrorMessage(errors.membershipNumber)}
          register={register("membershipNumber")}
          disabled={userUpdateBlocked}
        />
        {user.role !== Role.COMPANY_USER && (
          <>
            <Stack>
              {renderLockUpdate}
              <Typography>
                {`Dernière mise à jour : ${company?.lastUpdatedUser ?? ""} - ${company?.lastUpdated ?? ""}`}
              </Typography>
            </Stack>
            <Divider />
          </>
        )}
        <Stack spacing={1}>
          <Typography variant="body1">Logo de l'entreprise</Typography>
          {!fileErrorMessage && showPreview && (
            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
              <img src={URL.createObjectURL(logo[0])} alt="logo preview" style={{ width: "25%" }} />
            </Stack>
          )}
          {!fileErrorMessage && companyLogo && !showPreview && (
            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
              <img src={URL.createObjectURL(companyLogo)} alt="Logo" style={{ width: "25%" }} />
            </Stack>
          )}
          <Button
            variant="contained"
            component="label"
            startIcon={<FontAwesomeIcon size="xl" style={{ marginRight: "1rem" }} icon={faUpload} />}
          >
            {"Importer un logo"}
            <input hidden accept="image/png, image/jpg, image/jpeg, image/svg+xml" type="file" {...register("logo")} />
          </Button>
          {fileErrorMessage && (
            <Typography variant="body1" color="error">
              {fileErrorMessage}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <GenericTextField
          label="Donner l'accès à un autre utilisateur (email)"
          id="collaborator"
          error={!!collaboratorErrors.collaborator}
          helperText={stringifyErrorMessage(collaboratorErrors.collaborator)}
          register={collaboratorRegister("collaborator")}
        />
        <GenericButton
          text="Ajouter l'utilisateur sélectionné"
          onClick={handleCollaboratorSubmit(sendUserInCompany)}
          disabled={!collaboratorValid || checkEmailLoading}
        />
        {collaborators.length > 0 &&
          collaborators?.map((collaborator) => (
            <Stack direction="row" key={collaborator} justifyContent="space-between" alignItems="center">
              <Typography variant="body1">{collaborator}</Typography>
              <GenericIconButton
                icon={faTrash}
                tooltip={collaborator === user.email ? "" : "Supprimer ce collaborateur"}
                color={colors.deleteIcon}
                onClick={() => deleteCollaborator(collaborator)}
                size="sm"
                disabled={collaborator === user.email}
              />
            </Stack>
          ))}
      </Stack>
    </Stack>
  );

  return (
    <GenericDialog
      openDialog={openModal}
      handleClose={() => setOpenModal(false)}
      title={isUpdate ? "Modifier une entreprise" : "Créer une nouvelle entreprise"}
      onValid={handleSubmit(onCompanySubmit)}
      disabled={!createValid}
    >
      {renderCompanyForm()}
    </GenericDialog>
  );
}
