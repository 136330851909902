import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, IconButton, InputAdornment, MenuItem, Radio, Stack, TextField, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useAtom, useAtomValue } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { currentDuerpAtom, userShortAtom } from "../../../../../atoms/Atoms";
import { ICompany, ICompanyMembershipOrganism } from "../../../../../interfaces/Company";
import { IDuerpLightQuestion, IDuerpLightQuestionWithCertified, IDuerpSection } from "../../../../../interfaces/DuerpForm";
import { ProgressStatus } from "../../../../../interfaces/Form";
import { emptySection } from "../../../../../resources/AppConstants";
import colors from "../../../../../resources/cssConstant";
import { StatusCode } from "../../../../../resources/StatusCode";
import DuerpService from "../../../../../services/DuerpService";
import FormService from "../../../../../services/FormService";
import { stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import { downloadOiraForm } from "../../../../../utils/DownloadDocuments";
import { endSection, implicationSection, recapSection, validationSection } from "../../../../../utils/DuerpTransformation";
import { LinkifyText } from "../../../../../utils/LinkifyText";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import DuerpEndPage from "../DuerpEndPage/DuerpEndPage";
import DuerpValidationPage from "../DuerpValidationPage/DuerpValidationPage";
import DraggableRisk from "./DraggableRisk";

interface SectionDescriptionProps {
  section: IDuerpSection;
  sectionIndex: number;
  company: ICompany;
  updateTree: () => void;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  updateSectionConcernedState: (isCompanyConcerned: boolean) => void;
  updateRiskSection: (ordering: string[]) => void;
}

interface CustomOiraFormValues extends FieldValues {
  oira: string;
  oiraSection: string;
  oiraQuestion: string;
}

const questionSchema = yup.object().shape({
  question: yup
    .string()
    .trim()
    .max(2000, "Le titre du risque ne doit pas excéder 2000 caractères.")
    .min(1, "Vous ne pouvez pas laisser le titre du risque vide.")
});

const customOira = yup.object().shape({
  oira: yup
    .string()
    .trim()
    .min(1, "Vous ne pouvez pas laisser l'oira vide."),
  oiraSection: yup
    .string()
    .trim()
    .min(1, "Vous ne pouvez pas laisser la section vide."),
  oiraQuestion: yup
    .string()
    .trim()
});

const customOiraDefaultValues = {
  oira: "",
  oiraSection: "",
  oiraQuestion: ""
};

export default function SectionDescription({
  section,
  sectionIndex,
  updateSectionConcernedState,
  company,
  updateTree,
  goToPreviousPage,
  goToNextPage,
  updateRiskSection
}: SectionDescriptionProps) {
  const user = useAtomValue(userShortAtom);
  const [duerp, setDuerp] = useAtom(currentDuerpAtom);
  const [sectionData, setSectionData] = useState<IDuerpSection>(emptySection);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [customQuestions, setCustomQuestions] = useState<IDuerpLightQuestion[]>(
    duerp.sections[sectionIndex]?.questions ?? []
  );
  const [initialCustomQuestionOrder, setInitialCustomQuestionOrder] = useState<string[]>([]);

  const isImplSalPage = sectionIndex === -1;
  const isFirstPage = sectionIndex === -2;
  const isValidationPage = sectionIndex === -3;
  const isEndPage = sectionIndex === -4;

  const {
    register: registerQuestion,
    handleSubmit: handleSubmitQuestion,
    reset: resetQuestion,
    formState: { errors: errorsQuestion, isValid: isValidQuestion }
  } = useForm<Record<string, string>>({
    resolver: yupResolver(questionSchema),
    mode: "onTouched",
    defaultValues: {
      question: ""
    }
  });

  const {
    handleSubmit: handleSubmitCustomOira,
    reset: resetCustomOira,
    resetField: resetFieldCustomOira,
    getValues: getValuesCustomOira,
    formState: { isValid: isValidCustomOira },
    watch: watchCustomOira,
    control: controlCustomOira
  } = useForm({
    resolver: yupResolver(customOira),
    mode: "onTouched",
    defaultValues: customOiraDefaultValues
  });


  const oiraUuid = watchCustomOira("oira");
  const oiraSectionUuid = watchCustomOira("oiraSection");
  const oiraQuestionValue = watchCustomOira("oiraQuestion");

  const { data: simpleOiraData } = FormService().useGetOiraSimple();
  const { data: simpleOiraSectionData } = FormService().useGetOiraSectionSimple(oiraUuid);
  const { data: simpleOiraSectionRiskData } = FormService().useGetOiraSectionRiskSimple(oiraSectionUuid);

  const fetchSectionData = async () => {
    switch (sectionIndex) {
      case -1: {
        setSectionData(implicationSection);
        break;
      }
      case -2: {
        setSectionData(recapSection);
        break;
      }
      case -3: {
        setSectionData(validationSection);
        break;
      }
      case -4: {
        setSectionData(endSection);
        break;
      }
      default: {
        const res = await DuerpService().getDuerpSection(duerp.uuid, section.uuid);
        if (res.data) {
          setSectionData(res.data);
        }
      }
    }
  };

  useEffect(() => {
    setIsUpdated(false);
    setLoading(true);
    fetchSectionData().then(
      () => {
        const initialQuestions = duerp.sections[sectionIndex]?.questions ?? [];
        setCustomQuestions(initialQuestions);
        setInitialCustomQuestionOrder(initialQuestions.map((q) => q.uuid));
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, [section.uuid]);

  const handleRadioChange = (event) => {
    const newSectionData = { ...sectionData };
    const isConcerned = event.target.value === "true";
    newSectionData.companyConcerned = isConcerned;
    updateSectionConcernedState(isConcerned);
    setSectionData(newSectionData);
    setIsUpdated(true);
  };

  const orderingHasChanged = () => {
    const currentOrder = customQuestions.map((q) => q.uuid);
    return JSON.stringify(currentOrder) !== JSON.stringify(initialCustomQuestionOrder);
  };

  const updateRisksOrdering = async () => {
    const riskOrderMapping = customQuestions.reduce<Record<string, number>>((acc, risk, index) => {
      acc[risk.uuid] = index + 1;
      return acc;
    }, {});

    const res = await DuerpService().updateRisksOrdering(section.uuid, riskOrderMapping);
    if (res.status === StatusCode.OK) {
      updateTree();
      setInitialCustomQuestionOrder(customQuestions.map((q) => q.uuid));
    } else if (res.status === StatusCode.BAD_REQUEST) {
      toast.error("Erreur lors de la mise à jour de l'ordre des risques personnalisés. Veuillez vérifier que tous les risques sont renseignés.");
    } else {
      toast.error("Erreur lors de la mise à jour de l'ordre des risques personnalisés.");
    }
    return res;
  };

  const shouldUpdateOrdering = () =>
    duerp.progressStatus === ProgressStatus.NOT_FINALIZED &&
    orderingHasChanged();

  const shouldUpdateOptionalSection = () =>
    sectionData.optional &&
    isUpdated &&
    duerp.progressStatus === ProgressStatus.NOT_FINALIZED;

  const handleOrderingChange = async () => {
    const res = await updateRisksOrdering();
    if (res.status === StatusCode.OK) {
      const newOrder = customQuestions.map((q) => q.uuid);
      setInitialCustomQuestionOrder(newOrder);
      toast.success("Ordre des risques personnalisés sauvegardé.");
      // update the tree with the new order
      updateRiskSection(newOrder);
      goToNextPage();
    }
  };

  const handleOptionalSectionUpdate = async () => {
    const res = await DuerpService().updateDuerpSection(
      { companyConcerned: sectionData.companyConcerned },
      duerp.uuid,
      section.uuid
    );
    if (res.status === StatusCode.OK) {
      updateTree();
      goToNextPage();
    } else {
      toast.error(
        "Impossible de sauvegarder vos réponses pour le moment. Veuillez rafraîchir la page et ne pas continuer l’édition des autres questions."
      );
    }
  };

  const saveSection = async () => {
    if (shouldUpdateOrdering()) {
      await handleOrderingChange();
    } else if (shouldUpdateOptionalSection()) {
      await handleOptionalSectionUpdate();
    } else {
      goToNextPage();
    }
  };

  const addQuestion = async (data: FieldValues) => {
    const newQuestions = [...customQuestions];
    const newQuestionAdd = { title: data.question };
    const res = await DuerpService().postDuerpQuestion(newQuestionAdd, duerp.uuid, section.uuid);
    if (res.status === StatusCode.OK) {
      const newQuest = res.data as IDuerpLightQuestion;
      newQuestions.push(newQuest);
      setCustomQuestions(newQuestions);
      updateTree();
      toast.success("Risque personnalisé ajouté");
      resetQuestion();
    }
  };

  const addOiraQuestion = async (data: FieldValues) => {
    const newQuestions = [...customQuestions];
    const res = await DuerpService().postDuerpOiraQuestion(duerp.uuid, section.uuid, data.oiraQuestion);
    if (res.status === StatusCode.OK) {
      const newQuest = res.data as IDuerpLightQuestion;
      newQuestions.push(newQuest);
      setCustomQuestions(newQuestions);
      updateTree();
      resetCustomOira();
    }
  };

  const addOiraSection = async (data: FieldValues) => {
    const res = await DuerpService().postDuerpOiraQuestionsFromSection(
      duerp.uuid,
      section.uuid,
      data.oiraSection
    );
    if (res.status === StatusCode.OK) {
      setCustomQuestions([...customQuestions, ...res.data]);
      updateTree();
      if (res.data.length >= 1) {
        toast.success(res.data.length + " risques personnalisés ajoutés");
        resetCustomOira();
      } else {
        toast.warn("Tous les risque de la section sont déjà présents");
      }
    } else {
      toast.error("Erreur lors de l'ajout de la section");
    }
    return res;
  };

  const deleteQuestion = async (questionIndex: number) => {
    const updatedQuestionList = [...customQuestions];
    updatedQuestionList.splice(questionIndex, 1);

    const res = await DuerpService().deleteDuerpQuestion(duerp.uuid, section.uuid, customQuestions[questionIndex].uuid);
    if (res.status === StatusCode.NO_CONTENT) {
      updateTree();
      resetQuestion();
      resetCustomOira();
      setCustomQuestions(updatedQuestionList);
    }
  };

  const updateRiskTitle = (updatedRisk: IDuerpLightQuestionWithCertified) => {
    updateTree();

    const newQuest = updatedRisk as IDuerpLightQuestion;
    const updatedQuestions = customQuestions.map((quest) =>
      quest.uuid === newQuest.uuid ? newQuest : quest
    );

    setCustomQuestions(updatedQuestions);
  };

  const moveRisk = (dragIndex: number, hoverIndex: number) => {
    const updatedQuestions = Array.from(customQuestions);
    const [removed] = updatedQuestions.splice(dragIndex, 1);
    updatedQuestions.splice(hoverIndex, 0, removed);
    setCustomQuestions(updatedQuestions);
    setIsUpdated(true);
  };

  const oiraOptVal = useMemo(() => {
    if (simpleOiraData?.status === 200)
      return simpleOiraData?.data?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ));
    else {
      return <MenuItem key="loading" value="" disabled>{"Aucun résultats"}</MenuItem>;
    }
  }, [simpleOiraData]);

  const sectionOptVal = useMemo(() => {
    if (simpleOiraSectionData?.status === 200)
      return simpleOiraSectionData?.data?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ));
    else {
      return <MenuItem key="loading" value="" disabled>{"Aucun résultats"}</MenuItem>;
    }
  }, [simpleOiraSectionData]);

  const riskOptVal = useMemo(() => {
    if (simpleOiraSectionRiskData?.status === 200)
      return simpleOiraSectionRiskData?.data?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ));
    else {
      return <MenuItem key="loading" value="" disabled>{"Aucun résultats"}</MenuItem>;
    }
  }, [simpleOiraSectionRiskData]);

  const renderCustomSection = () => {
    return (
      <Stack>
        <Stack direction="row" spacing={2} sx={{ mr: 5, mb: 2 }}>
          <Box flexGrow={1}>
            <GenericTextField
              label="Titre du risque personnalisé"
              error={!!errorsQuestion.question}
              helperText={stringifyErrorMessage(errorsQuestion.question)}
              id="question"
              placeholder="Saisir un titre de risque personnalisé"
              register={registerQuestion("question")}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmitQuestion(addQuestion)();
                }
              }}
            />
          </Box>
          <GenericButton
            text="Ajouter ce risque"
            onClick={handleSubmitQuestion(addQuestion)}
            disabled={
              !isValidQuestion ||
              duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
            }
          />
        </Stack>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {"et/ou"}
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mr: 5, mb: 2 }}>
          <Stack direction="row" spacing={2} flexGrow={1}>
            <Controller
              name={"oira"}
              control={controlCustomOira}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <TextField
                  label={"Questionnaire"}
                  fullWidth
                  select
                  error={!!fieldState.error}
                  helperText={stringifyErrorMessage(fieldState.error)}
                  {...field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e);
                    resetFieldCustomOira("oiraSection");
                    resetFieldCustomOira("oiraQuestion");
                  }}
                >
                  {oiraOptVal}
                </TextField>
              )}
            />
            <Controller
              name={"oiraSection"}
              control={controlCustomOira}
              render={({ field: { onChange, ...field }, fieldState }) => (
                <TextField
                  label={"Section"}
                  fullWidth
                  select
                  error={!!fieldState.error}
                  helperText={stringifyErrorMessage(fieldState.error)}
                  {...field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e);
                    resetFieldCustomOira("oiraQuestion");
                  }}
                  disabled={!oiraUuid}
                >
                  {sectionOptVal}
                </TextField>
              )}
            />
            <Controller
              name={"oiraQuestion"}
              control={controlCustomOira}
              render={({ field, fieldState }) => (
                <TextField
                  label={"Question"}
                  fullWidth
                  select
                  error={!!fieldState.error}
                  helperText={stringifyErrorMessage(fieldState.error)}
                  {...field}
                  disabled={!oiraSectionUuid}
                  InputProps={{
                    style: {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    },
                    endAdornment: field.value ? (
                      <InputAdornment position="end">
                        <IconButton onClick={() => resetFieldCustomOira("oiraQuestion")} edge="end" size="small">
                          <GridCloseIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }}
                >
                  {riskOptVal}
                </TextField>
              )}
            />
          </Stack>
          <GenericButton
            text="Ajouter cette section"
            onClick={handleSubmitCustomOira(addOiraSection)}
            disabled={
              !oiraUuid ||
              !oiraSectionUuid ||
              !!oiraQuestionValue ||
              duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
            }
          />
          <GenericButton
            text="Ajouter ce risque"
            onClick={handleSubmitCustomOira(addOiraQuestion)}
            disabled={
              !oiraUuid ||
              !oiraSectionUuid ||
              !oiraQuestionValue ||
              !isValidCustomOira ||
              duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
            }
          />
        </Stack>
        <Typography variant="body2" sx={{ mb: 1 }}>
          {"Risques personnalisés ajoutés"}
        </Typography>
        <DndProvider backend={HTML5Backend}>
          {customQuestions.length === 0 ? (
            <Typography variant="body1">Aucun risque ajouté</Typography>
          ) : (
             customQuestions.map((question, index) => (
               <DraggableRisk
                 key={question.uuid}
                 risk={question}
                 index={index}
                 moveRisk={moveRisk}
                 deleteRisk={deleteQuestion}
                 user={user}
                 duerp={duerp}
                 updateRiskTitle={updateRiskTitle}
               />
             ))
           )}
        </DndProvider>
      </Stack>
    );
  };

  const renderFirstPage = () => {
    const companyInfo = "Informations de l'entreprise";
    const companyName = "Raison sociale ou dénomination : ";
    const companyOrganism = "Votre service de prévention et de santé au travail : ";
    const membershipNumber = "Numéro d'adhérent SPSTI : ";
    const collaboratorsTitle = "Collaborateurs de l'entreprise : ";
    const membershipOrganism =
      company?.membershipOrganization === ICompanyMembershipOrganism.OTHER
      ? "Autre organisme"
      : company?.membershipOrganization.toString().replace("_", " ");
    const collaboratorsList = company.collaborators.concat(company.externalCollaborators);
    return (
      <Stack spacing={2} width="50%">
        <Typography variant="question" sx={{ mb: 3 }}>
          {companyInfo}
        </Typography>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{companyName}</Typography>
          <Typography variant="body1">{company?.companyName}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{companyOrganism}</Typography>
          <Typography variant="body1">{membershipOrganism}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{membershipNumber}</Typography>
          <Typography variant="body1">{company?.membershipNumber}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{collaboratorsTitle}</Typography>
          <Stack alignItems="flex-end" justifyContent="flex-end">
            {collaboratorsList.map((collaborator) => (
              <Typography key={collaborator} variant="body1">
                {collaborator}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const renderOptionalQuestion = () => (
    <Stack spacing={3}>
      <Typography variant="question">Votre entreprise est-elle concernée par cette étape ?</Typography>

      <Stack spacing={1} alignItems="flex-start">
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
            value="true"
            checked={sectionData.companyConcerned}
            onChange={handleRadioChange}
          />
          <Typography variant="body1">Oui</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
            value="false"
            checked={!sectionData.companyConcerned}
            onChange={handleRadioChange}
          />
          <Typography variant="body1">Non</Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  const downloadForm = async () => {
    setLoading(true);
    await downloadOiraForm(duerp.oiraFormUuid);
    setLoading(false);
  };

  const getNextBtnText = () => {
    if (duerp.progressStatus === ProgressStatus.FINALIZED) return "Suivant";
    return (sectionData.optional && isUpdated) || orderingHasChanged() ? "Enregistrer et continuer" : "Continuer";
  };

  const renderSection = () => (
    <>
      {sectionData.optional && renderOptionalQuestion()}
      {isFirstPage && renderFirstPage()}
      {sectionData.title === "Implication des salariés" && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <GenericButton
            text="Télécharger l'évaluation"
            icon={faDownload}
            onClick={() => downloadForm()}
            color={colors.primary}
            disabled={loading}
          />
        </Box>
      )}
    </>
  );

  const renderInternalSection = () =>
    isValidationPage ? (
      <DuerpValidationPage
        companyUuid={company.uuid}
        updateTree={updateTree}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
      />
    ) : (
      <Box>
        <Typography variant="h3" sx={{ mb: 5 }}>
          {sectionData.title}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line", marginY: 10, width: "70%" }}>
          {LinkifyText(sectionData.description)}
        </Typography>
        {sectionData.customRisksSection ? renderCustomSection() : renderSection()}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 5 }}>
          <GenericButton onClick={() => goToPreviousPage()} text="Précédent" />
          <GenericButton onClick={() => saveSection()} text={getNextBtnText()} />
        </Stack>
      </Box>
    );

  return isEndPage ? (
    <DuerpEndPage companyUuid={company.uuid} goToPreviousPage={goToPreviousPage} />
  ) : (
           renderInternalSection()
         );
}
