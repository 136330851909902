import { yupResolver } from "@hookform/resolvers/yup";
import { Clear } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { INavigationElement } from "../../../../../utils/DuerpTransformation";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import DuerpSearchResults from "./containers/DuerpSearchResults";

interface DuerpSearchDialogProps {
  open: boolean;
  onClose: (flatIndex?: number) => void;
  duerpUuid: string;
  flatDuerp: INavigationElement[];
}

interface DuerpSearchDialogForm {
  search?: string;
}

const duerpSearchSchema = yup.object().shape({
  search: yup.string().required("Le champ de recherche est requis.").min(3, "La recherche doit contenir au moins 3 caractères.")
});

const duerpSearchDefaultValues: DuerpSearchDialogForm = {
  search: ""
};

export default function DuerpSearchDialog({ open, onClose, duerpUuid, flatDuerp }: Readonly<DuerpSearchDialogProps>) {
  const [search, setSearch] = useState<string | undefined>(undefined);

  const {
    register: registerDuerpSearch,
    handleSubmit: handleSubmitDuerpSearch,
    formState: { errors: errorsDuerpSearch },
    reset: resetDuerpSearch,
    resetField: resetFieldDuerpSearch
  } = useForm<DuerpSearchDialogForm>({
    resolver: yupResolver(duerpSearchSchema),
    defaultValues: duerpSearchDefaultValues
  });

  const handleClose = () => {
    resetDuerpSearch();
    onClose();
  };
  const handleSearch = handleSubmitDuerpSearch((data) => setSearch(data.search));

  const inputProps = {
    endAdornment: <InputAdornment position={"end"} sx={{ marginLeft: 0 }}>
      <IconButton edge={"end"} onClick={() => resetFieldDuerpSearch("search")}><Clear /></IconButton>
    </InputAdornment>
  };

  const searchResults = <DuerpSearchResults duerpUuid={duerpUuid} search={search} flatDuerp={flatDuerp} onClose={onClose} />;

  return (
    <GenericDialog openDialog={open} handleClose={handleClose} title={"Recherche dans le DUERP"} confirmLabel={"Rechercher"} onValid={handleSearch}
                   afterActions={searchResults}>
      <GenericTextField label={"Recherche"} register={registerDuerpSearch("search")} error={!!errorsDuerpSearch.search} helperText={errorsDuerpSearch.search?.message}
                        InputProps={inputProps} />
    </GenericDialog>
  );
}
