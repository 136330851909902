import { faBars, faDownload, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemIcon, Menu, MenuItem, MenuList, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { userShortAtom } from "../../../../../atoms/Atoms";
import { ICompanyShort } from "../../../../../interfaces/Company";
import { IShortForm, ProgressStatus, Type } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import colors from "../../../../../resources/cssConstant";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../../../resources/Routes";
import CompanyService from "../../../../../services/CompanyService";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import DownloadDocumentButton from "../../../DocumentActions/DownloadDocumentButton/DownloadDocumentButton";
import DuplicateDocumentButton from "../../../DocumentActions/DuplicateDocumentButton/DuplicateDocumentButton";
import NewDocumentButton from "../../../DocumentActions/NewDocumentButton/NewDocumentButton";

interface DuerpMenuOptionsProps {
  document: IShortForm;
  companyUuid: string;
}

export default function DuerpMenuOptions({ document, companyUuid }: Readonly<DuerpMenuOptionsProps>) {
  const user = useAtomValue(userShortAtom);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [companyShort, setCompanyShort] = useState<ICompanyShort>({
    collaborators: [],
    companyName: "",
    complementaryFormsNumber: 0,
    duerpsNumber: 0,
    lastComplementaryForm: null,
    lastDuerp: null,
    externalCollaborators: [],
    logoUuid: "",
    membershipNumber: "",
    membershipOrganization: "",
    uuid: "",
    updateBlocked: false,
    lastUpdated: "",
    lastUpdatedUser: ""
  });
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchCompanyShort = async () => {
    const res = await CompanyService().getCompanyShortById(companyUuid);
    if (res.data) {
      setCompanyShort(res.data);
    }
  };

  useEffect(() => {
    fetchCompanyShort();
  }, []);

  useEffect(() => {
    handleClose();
  }, [openDownloadModal]);

  const renderDownloadModal = () => (
    <GenericDialog
      openDialog={openDownloadModal}
      handleClose={() => setOpenDownloadModal(false)}
      title={`Télécharger DUERP et Plan d'Actions`}
    >
      <DownloadDocumentButton documentType={document.documentType} documentUuid={document.uuid} endPageButtons />
    </GenericDialog>
  );

  const goToComplementaryForm = () => {
    const complementaryForm = companyShort.lastComplementaryForm;
    const path =
      user.role === Role.COMPANY_USER ? frontOfficeRoutes.complementary_form : backOfficeRoutes.complementary_form;
    navigate(generatePath(path, { refNumber: complementaryForm?.reference.replaceAll("/", "%2F") }), {
      state: { company: companyShort, uuid: complementaryForm?.uuid }
    });
  };

  const renderComplementaryFormButton = () =>
    companyShort?.lastComplementaryForm ? (
      <MenuItem onClick={goToComplementaryForm}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faFile} size="xs" color={colors.primary} />
        </ListItemIcon>
        <Typography variant="body1">Voir le questionnaire complémentaire</Typography>
      </MenuItem>
    ) : (
      <NewDocumentButton
        buttonWithIcon={false}
        menuButton
        company={companyShort}
        documentType={Type.COMPLEMENTARY_FORM}
        oiras={[]}
      />
    );

  return (
    <>
      {renderDownloadModal()}
      <GenericIconButton onClick={handleClick} icon={faBars} tooltip="Options" />
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => setOpenDownloadModal(true)}
            disabled={document.progressStatus === ProgressStatus.NOT_FINALIZED}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faDownload} size="xs" color={colors.primary} />
            </ListItemIcon>
            <Typography variant="body1">{`Télécharger DUERP et Plan d'Actions`}</Typography>
          </MenuItem>
          {renderComplementaryFormButton()}
          <DuplicateDocumentButton
            documentType={Type.DUERP}
            documentUuid={document.uuid}
            company={companyShort}
            menuButton
          />
        </MenuList>
      </Menu>
    </>
  );
}
