import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

interface AutocompleteOption {
  id: string;
  name: string;
}

interface GenericAutoCompleteProps {
  name: string;
  control: Control<FieldValues, any> | undefined;
  id: string;
  label: string;
  options: any[];
  freeSolo?: boolean;
  defaultValue?;
  isControlled?: boolean;
  onChange?;
  inputValue?: AutocompleteOption | null;
}

export default function GenericAutoComplete({
  name,
  control,
  id,
  label,
  options,
  freeSolo,
  defaultValue,
  isControlled,
  onChange,
  inputValue,
}: Readonly<GenericAutoCompleteProps>) {
  const getAutoCompleteOptions = (optionsValues: any[]) => {
    const optionsArray: AutocompleteOption[] = [];

    optionsValues.forEach((option) => {
      const optionObject: AutocompleteOption = {
        id: option.uuid,
        name: option.name ?? option.companyName ?? option.title,
      };
      optionsArray.push(optionObject);
    });
    return optionsArray;
  };

  return !isControlled ? (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          fullWidth
          freeSolo={freeSolo}
          id={id}
          getOptionKey={(option) => option?.id + option?.name}
          options={getAutoCompleteOptions(options)}
          defaultValue={defaultValue}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option?.id === value.id && option?.name === value.name}
          renderInput={(params) => <TextField {...params} label={label} />}
          onChange={(_, data) => {
            onChange(data);
          }}
        />
      )}
    />
  ) : (
    <Autocomplete
      value={inputValue}
      fullWidth
      freeSolo={freeSolo}
      id={id}
      getOptionKey={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option.id + option.name;
      }}
      options={getAutoCompleteOptions(options)}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option?.name || "";
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id && option?.name === value?.name}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(_, data) => {
        onChange(data);
        return data;
      }}
    />
  );
}
