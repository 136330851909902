import { Grid, Stack } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { currentComplementaryFormAtom, userAtom } from "../../../atoms/Atoms";
import { ICompany, ICompanyShort } from "../../../interfaces/Company";
import { IComplementaryForm, IComplementarySection } from "../../../interfaces/ComplementaryForm";
import { ProgressStatus } from "../../../interfaces/Form";
import { Role } from "../../../interfaces/User";
import { emptyComplementaryForm, endSection } from "../../../resources/AppConstants";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../resources/Routes";
import ComplementaryFormService from "../../../services/ComplementaryFormService";
import Header from "../Header/Header";
import ProgressChip from "../ProgressChip/ProgressChip";
import ComplementaryFormMenuOptions from "./containers/ComplementaryFormMenuOptions/ComplementaryFormMenuOptions";
import ComplementaryFormSection from "./containers/ComplementaryFormSection/ComplementaryFormSection";
import ComplementaryFormStepper from "./containers/ComplementaryFormStepper/ComplementaryFormStepper";
import SkeletonComplementaryForm from "./SkeletonComplementaryForm";

export default function ComplementaryForm() {
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const company: ICompanyShort | ICompany = location.state.company;
  const cfRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true);
  const [complementaryForm, setComplementaryForm] = useAtom(currentComplementaryFormAtom);
  const [sections, setSections] = useState<IComplementarySection[]>([]);
  const [navigationIndex, setNavigationIndex] = useState<number>(0);
  const [canEditForm, setCanEditForm] = useState<boolean>(false);
  const [forceToOpenDuerpModal, setForceToOpenDuerpModal] = useState<boolean>(false);

  const checkEdition = (retrievedForm: IComplementaryForm) => {
    if (user.role === Role.COMPANY_USER) {
      setCanEditForm(retrievedForm.progressStatus === ProgressStatus.NOT_FINALIZED);
    } else {
      setCanEditForm(
        retrievedForm.progressStatus !== ProgressStatus.ARCHIVED &&
          retrievedForm.progressStatus !== ProgressStatus.FINALIZED,
      );
    }
  };

  const fetchQuestions = async () => {
    const res = await ComplementaryFormService().getFormTemplate();
    let retrievedSections: IComplementarySection[] = [];
    if (res?.data) {
      retrievedSections = res.data;
      if (user.role === Role.COMPANY_USER) {
        // on a 6 sections de questions, fixes. La dernière section n'est pas visible côté FO.
        retrievedSections.pop();
      }
      retrievedSections.push(endSection);
      setSections(retrievedSections);
    } else {
      setSections([]);
    }
  };

  const fetchComplementaryFormData = async () => {
    const { uuid } = location.state;
    const formData = await ComplementaryFormService().getComplementaryFormByUuid(uuid);
    if (formData.data) {
      const retrievedForm = formData.data;
      setComplementaryForm(retrievedForm);
      checkEdition(retrievedForm);
    } else {
      setComplementaryForm(emptyComplementaryForm);
    }
    await fetchQuestions();
  };

  useEffect(() => {
    setLoading(true);
    fetchComplementaryFormData().then(
      () => {},
      () => {
        toast.error("Aucune donnée à afficher.");
        setLoading(false);
        navigate(user.role === Role.COMPANY_USER ? frontOfficeRoutes.home : backOfficeRoutes.home);
      },
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    checkEdition(complementaryForm);
  }, [complementaryForm]);

  useEffect(() => {
    cfRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [navigationIndex]);

  const saveSection = async () => {
    const res = await ComplementaryFormService().updateComplementaryForm(complementaryForm.uuid, complementaryForm);
    if (res.data) {
      setComplementaryForm(res.data);
    } else {
      toast.error("Impossible de sauvegarder vos réponses pour le moment.");
    }
  };

  const goToNextPage = async () => {
    setLoading(true);
    if (canEditForm) {
      await saveSection();
    }
    checkEdition(complementaryForm);
    setNavigationIndex(navigationIndex + 1);
    setLoading(false);
  };

  const goToPreviousPage = () => {
    setLoading(true);
    setNavigationIndex(navigationIndex - 1);
    checkEdition(complementaryForm);
    setLoading(false);
  };

  const renderFormContent = () => {
    const section: IComplementarySection = sections[navigationIndex];
    return (
      <ComplementaryFormSection
        section={section}
        canEdit={canEditForm}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
        renderNext={navigationIndex < sections.length - 1}
        renderPrevious={navigationIndex > 0}
        forceToOpenDuerpModal={() => setForceToOpenDuerpModal(true)}
      />
    );
  };

  return (
    <Stack ref={cfRef} sx={{ height: "100%" }}>
      <Header marginBottom={0} title={`Questionnaire complémentaire - ${complementaryForm.reference}`}>
        <ProgressChip progressType={complementaryForm.progressStatus} />
        <ComplementaryFormMenuOptions
          company={company}
          forceToOpenDuerpModal={forceToOpenDuerpModal}
          onDuerpModalClose={() => setForceToOpenDuerpModal(false)}
        />
      </Header>
      <Grid sx={{ height: "100%" }} container>
        <Grid item xs={2}>
          {sections.length > 0 && (
            <ComplementaryFormStepper
              sections={sections}
              navigationIndex={navigationIndex}
              setNavigationIndex={setNavigationIndex}
            />
          )}
        </Grid>
        <Grid item xs sx={{ m: 2 }}>
          {loading || sections.length === 0 ? <SkeletonComplementaryForm /> : renderFormContent()}
        </Grid>
      </Grid>
    </Stack>
  );
}
