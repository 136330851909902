import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import React, { useMemo } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { IDuerpLightQuestionWithCertified } from "../../../../../interfaces/DuerpForm";
import { StatusCode } from "../../../../../resources/StatusCode";
import DuerpService from "../../../../../services/DuerpService";
import { stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";

interface UpdateRiskTitleDialogProps {
  open: boolean;
  onClose: () => void;
  onValid: (updatedRisk: IDuerpLightQuestionWithCertified) => void;
  risk: IDuerpLightQuestionWithCertified;
}

const riskTitleSchema = yup.object().shape({
  riskTitle: yup
    .string()
    .trim()
    .max(2000, "Le titre du risque ne doit pas excéder 2000 caractères.")
    .min(1, "Vous ne pouvez pas laisser le titre du risque vide.")
});

export default function UpdateRiskTitleDialog({ open, onClose, onValid, risk }: Readonly<UpdateRiskTitleDialogProps>) {

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid }
  } = useForm<Record<string, string>>({
    resolver: yupResolver(riskTitleSchema),
    mode: "onTouched",
    defaultValues: {
      riskTitle: risk.title
    }
  });

  const newTitle = watch("riskTitle");

  const titleChange = useMemo(
    () => risk.title !== newTitle
    , [risk.title, newTitle]);

  const updateTitle = async (data: FieldValues) => {
    const newTitle = data.riskTitle;
    if (titleChange) {
      DuerpService().patchCustomRiskTitle(risk.uuid, newTitle).then((res) => {
        if (res.status === StatusCode.OK) {
          onValid(res.data);
          onClose();
        }
      });
    }
  };

  return (
    <GenericDialog
      openDialog={open}
      handleClose={onClose}
      title={"Modifier le titre du risque"}
      disabled={!isValid || !titleChange}
      onValid={handleSubmit(updateTitle)}
    >
      <Controller
        name={"riskTitle"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            label={"Nouveau titre du risque personnalisé"}
            placeholder={"Saisir un nouveau titre de risque personnalisé"}
            fullWidth
            error={!!fieldState.error}
            helperText={stringifyErrorMessage(fieldState.error)}
            {...field}
          />
        )}
      />
    </GenericDialog>
  );
}
