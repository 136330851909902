import { AxiosResponse } from "axios";
import useSWR from "swr";
import { IOptionValue } from "../interfaces/Filter";
import { IOiraForm, IOiraShortForm } from "../interfaces/OiraForm";
import { Endpoints } from "../resources/Endpoints";
import { getUseSwrKey } from "../utils/Services";
import HttpService from "./HttpService";

export default function FormService() {
  const getAllOiras = (): Promise<AxiosResponse<IOiraShortForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.base,
      method: "get"
    });

  const getAllPublishedOiras = (): Promise<AxiosResponse<IOiraShortForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.base,
      method: "get",
      params: {
        published: true
      }
    });

  const getOiraById = (uuid: string): Promise<AxiosResponse<IOiraForm>> =>
    HttpService.fetch({
      url: Endpoints.oira.getOira(uuid),
      method: "get"
    });

  const getOiraGroup = (groupUuid: string): Promise<AxiosResponse<IOiraForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.getOiraGroup(groupUuid),
      method: "get"
    });

  const publishOiraVersion = (uuid: string): Promise<AxiosResponse<IOiraForm[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.publishOiraVersion(uuid),
      method: "put"
    });

  const unpublishOira = (groupUuid: string): Promise<AxiosResponse> =>
    HttpService.fetch({
      url: Endpoints.oira.unpublishOira(groupUuid),
      method: "put"
    });

  const updateOiraSectionDescription = (
    oiraUuid: string,
    sectionUuid: string,
    description: string
  ): Promise<AxiosResponse<IOiraForm>> =>
    HttpService.fetch({
      url: Endpoints.oira.updateSectionDescription(oiraUuid, sectionUuid),
      method: "put",
      data: description,
      headers: { "Content-Type": "text/plain" }
    });

  const updateOiraQuestionDescription = (
    oiraUuid: string,
    questionUuid: string,
    description: string
  ): Promise<AxiosResponse<IOiraForm>> =>
    HttpService.fetch({
      url: Endpoints.oira.updateQuestionDescription(oiraUuid, questionUuid),
      method: "put",
      data: description,
      headers: { "Content-Type": "text/plain" }
    });

  const importOiraForm = (file: File): Promise<AxiosResponse<IOiraForm>> => {
    const formData = new FormData();
    formData.append("file", file);
    return HttpService.fetch({
      url: Endpoints.oira.importOiraForm,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });
  };

  const importOiraFormInGroup = (file: File, groupUuid: string): Promise<AxiosResponse<IOiraForm>> => {
    const formData = new FormData();
    formData.append("file", file);
    return HttpService.fetch({
      url: Endpoints.oira.importOiraInGroup(groupUuid),
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });
  };

  const downloadOiraForm = (uuid: string): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.oira.downloadOiraForm(uuid),
      method: "get",
      responseType: "blob"
    });

  // ---------------------------Custom risque Section---------------------------

  const getOiraSimple = (): Promise<AxiosResponse<IOptionValue[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.getOiraSimple,
      method: "get"
    });

  const useGetOiraSimple = () =>
    useSWR(
      () => getUseSwrKey([], [], Endpoints.oira.getOiraSimple),
      () => getOiraSimple(),
      { revalidateOnFocus: false }
    );

  const getOiraSectionSimple = (oiraUuid: string): Promise<AxiosResponse<IOptionValue[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.getOiraSectionSimple(oiraUuid),
      method: "get"
    });

  const useGetOiraSectionSimple = (oiraUuid: string) =>
    useSWR(
      () => getUseSwrKey([oiraUuid], [], Endpoints.oira.getOiraSectionSimple(oiraUuid)),
      ([oiraUuid]) => getOiraSectionSimple(oiraUuid),
      { revalidateOnFocus: false }
    );

  const getOiraSectionRiskSimple = (sectionUuid: string): Promise<AxiosResponse<IOptionValue[]>> =>
    HttpService.fetch({
      url: Endpoints.oira.getOiraSectionRiskSimple(sectionUuid),
      method: "get"
    });

  const useGetOiraSectionRiskSimple = (sectionUuid: string) =>
    useSWR(
      () => getUseSwrKey([sectionUuid], [], Endpoints.oira.getOiraSectionRiskSimple(sectionUuid)),
      ([sectionUuid]) => getOiraSectionRiskSimple(sectionUuid),
      { revalidateOnFocus: false }
    );

  return {
    getAllOiras,
    getAllPublishedOiras,
    getOiraById,
    updateOiraSectionDescription,
    updateOiraQuestionDescription,
    getOiraGroup,
    publishOiraVersion,
    unpublishOira,
    importOiraForm,
    importOiraFormInGroup,
    downloadOiraForm,
    useGetOiraSimple,
    useGetOiraSectionSimple,
    useGetOiraSectionRiskSimple
  };
}
