import { Search } from "@mui/icons-material";
import { Grid, IconButton, Stack } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { currentDuerpAtom, userAtom } from "../../../atoms/Atoms";
import { ICompany } from "../../../interfaces/Company";
import { IDuerpForm, IDuerpLightQuestion, IDuerpLightSection, IDuerpQuestion, IDuerpSection } from "../../../interfaces/DuerpForm";
import { ProgressStatus } from "../../../interfaces/Form";
import { Role } from "../../../interfaces/User";
import { emptyCompany, emptyDuerp, emptyQuestion } from "../../../resources/AppConstants";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../resources/Routes";
import CompanyService from "../../../services/CompanyService";
import DuerpService from "../../../services/DuerpService";
import { flattenDuerp, INavigationElement } from "../../../utils/DuerpTransformation";
import AccompanimentChip from "../AccompanimentChip/AccompanimentChip";
import Header from "../Header/Header";
import ProgressChip from "../ProgressChip/ProgressChip";
import DuerpMenuOptions from "./containers/DuerpMenuOptions/DuerpMenuOptions";
import DuerpQuestion from "./containers/DuerpQuestion/DuerpQuestion";
import DuerpQuestionSkeleton from "./containers/DuerpQuestion/DuerpQuestionSkeleton";
import DuerpSearchDialog from "./containers/DuerpSearchDialog/DuerpSearchDialog";
import DuerpStepper from "./containers/DuerpStepper/DuerpStepper";
import SectionDescription from "./containers/SectionDescription/SectionDescription";
import SkeletonDuerp from "./SkeletonDuerp";

export default function Duerp() {
  const navigate = useNavigate();
  const user = useAtomValue(userAtom);
  const duerpRef = useRef<HTMLInputElement>(null);

  const [duerp, setDuerp] = useAtom(currentDuerpAtom);
  const [loading, setLoading] = useState<boolean>(true);
  const [question, setQuestion] = useState<IDuerpQuestion>(emptyQuestion);
  const [flatDuerp, setFlatDuerp] = useState<INavigationElement[]>([]);
  const [flatIndex, setFlatIndex] = useState<number>(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [progressValue, setProgressValue] = useState<number>(0);
  const [company, setCompany] = useState<ICompany>(emptyCompany);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchElementIndex, setSearchElementIndex] = useState<number | undefined>(undefined);

  const { refNumber } = useParams();

  interface IDuerpLightQuestionWithOrdinal extends IDuerpLightQuestion {
    ordinal: number;
  }

  const setFullyCompleted = (flattenedDuerp: INavigationElement[]) => {
    const newCompleted = { ...completed };
    flattenedDuerp.forEach((element, index) => {
      newCompleted[index] = true;
    });
    setCompleted(newCompleted);
    setProgressValue(100);
  };

  const updateCompletedStatus = () => {
    const newCompleted = { ...completed };
    flatDuerp.forEach((navigationElement, index) => {
      newCompleted[index] =
        navigationElement.position.currentSectionIndex > -1 ? navigationElement.element.completed : false;
    });
    const completedArray: boolean[] = Object.values(newCompleted);
    const numberOfTrueValues: number = completedArray.reduce(
      (sum: number, isQuestionCompleted: boolean) => (isQuestionCompleted ? sum + 1 : sum),
      0
    );
    // we -4 because we remove the sections that are not part of the DUERP
    setProgressValue(Math.round((100 * numberOfTrueValues) / (flatDuerp.length - 4)));
    setCompleted(newCompleted);
  };

  const fetchDuerpData = async (newDuerp?: IDuerpForm) => {
    let retrievedDuerp = emptyDuerp;
    if (newDuerp) {
      retrievedDuerp = newDuerp;
    } else {
      const res = await DuerpService().getDuerpByReference(refNumber !== undefined ? refNumber.toString() : "");
      if (res.data) {
        const { companyUuid } = res.data;
        const companyRes = await CompanyService().getCompanyById(companyUuid);
        setCompany(companyRes.data);
        retrievedDuerp = res.data;
      }
    }
    setDuerp(retrievedDuerp);
    const flattenedDuerp = flattenDuerp(retrievedDuerp);

    setFlatDuerp(flattenedDuerp);
  };

  useEffect(() => {
    setLoading(true);
    fetchDuerpData().then(
      () => {
        setProgressValue(0);
        setLoading(false);
      },
      () => {
        toast.error("Aucune donnée à afficher.");
        setLoading(false);
        navigate(user.role === Role.COMPANY_USER ? frontOfficeRoutes.home : backOfficeRoutes.home);
      }
    );
  }, []);

  const updateQuestionState = (index: number) => {
    const element = flatDuerp[index]?.element ?? null;
    if (element === null) return;
    if (flatDuerp[flatIndex].position.currentQuestionIndex !== -1) {
      setQuestion(element as IDuerpQuestion);
    } else {
      setQuestion(emptyQuestion);
    }
  };

  useEffect(() => {
    duerpRef.current?.scrollIntoView({ behavior: "smooth" });
    updateQuestionState(flatIndex);
  }, [flatIndex]);

  useEffect(() => {
    if (duerp.progressStatus === ProgressStatus.FINALIZED) {
      setFullyCompleted(flatDuerp);
    } else {
      updateCompletedStatus();
    }
  }, [flatDuerp]);

  const updateSectionConcernedState = (isConcerned: boolean, duerpToUpdate?: IDuerpForm) => {
    const updatedDuerp = duerpToUpdate ?? { ...duerp };

    const { position } = flatDuerp[flatIndex];
    if (position.currentSectionIndex > -1) {
      const retrievedSections =
        updatedDuerp.sections[position.currentSectionIndex].subSections ?? updatedDuerp.sections;
      if (position.currentSubSectionIndex > -1) {
        retrievedSections[position.currentSubSectionIndex].companyConcerned = isConcerned;
      } else {
        updatedDuerp.sections[position.currentSectionIndex].companyConcerned = isConcerned;
      }
    }
    setDuerp(updatedDuerp);
    setFlatDuerp(flattenDuerp(updatedDuerp));
  };

  const goToNextSection = (duerpToUpdate?: IDuerpForm) => {
    const currentSection = flatDuerp[flatIndex].element as IDuerpLightSection;
    let increment = 0;
    if (currentSection.subSections) {
      increment = currentSection.subSections.length + flatIndex;
      currentSection.subSections.forEach((subsection) => {
        increment += subsection.questions?.length ?? 0;
      });
    } else if (!currentSection.subSections && currentSection.questions) {
      increment = currentSection.questions.length + flatIndex;
    }

    const nextSection = (navElement: INavigationElement) => {
      const section = navElement.element as IDuerpLightSection;
      return (section.questions || section.subSections) && flatDuerp.indexOf(navElement) > increment;
    };

    const nextIndex = flatDuerp.findIndex(nextSection) ?? flatDuerp.length;
    updateSectionConcernedState(false, duerpToUpdate);
    setFlatIndex(nextIndex);
    updateQuestionState(nextIndex);
  };

  const goToHomePage = () => {
    if (user.role === Role.COMPANY_USER) navigate(frontOfficeRoutes.home);
    else navigate(backOfficeRoutes.home);
  };

  const goToNextPage = async () => {
    setLoading(true);
    let updatedDuerp: IDuerpForm | undefined;
    let skipActualSection = false;

    if (flatDuerp[flatIndex].position.currentQuestionIndex !== -1) {
      skipActualSection = !duerp.sections[flatDuerp[flatIndex].position.currentSectionIndex].companyConcerned;
    } else {
      const elem = flatDuerp[flatIndex].element as IDuerpLightSection;
      skipActualSection = !elem.companyConcerned;
    }
    if (skipActualSection) {
      goToNextSection(updatedDuerp);
    } else {
      updateSectionConcernedState(true, updatedDuerp);
      setFlatIndex(flatIndex + 1);
      updateQuestionState(flatIndex + 1);
    }
    setLoading(false);
  };

  const handleNewFlatIndex = (sectionIsConcerned: boolean, sectionElement: IDuerpLightSection) => {
    if (sectionIsConcerned) {
      setFlatIndex(flatIndex - 1);
      updateQuestionState(flatIndex - 1);
    } else {
      const index = flatDuerp.findIndex((navElement) => navElement.element.uuid === sectionElement.uuid);
      setFlatIndex(index);
      updateQuestionState(index);
    }
  };

  const goToPreviousSection = () => {
    // checks where to go if previous section was ignored
    if (!flatDuerp[flatIndex - 1]) {
      goToHomePage();
      return;
    }
    const elementSectionIndex = flatDuerp[flatIndex - 1].position.currentSectionIndex;
    const elementSubSectionIndex = flatDuerp[flatIndex - 1].position.currentSubSectionIndex;
    if (flatDuerp[flatIndex - 1].position.currentQuestionIndex !== -1) {
      const sectionIsConcerned = duerp.sections[elementSectionIndex].companyConcerned;
      const subsections = duerp.sections[elementSectionIndex].subSections;
      if (elementSubSectionIndex > -1 && subsections) {
        const subSectionIsConcerned = subsections[elementSubSectionIndex].companyConcerned;
        handleNewFlatIndex(subSectionIsConcerned, subsections[elementSubSectionIndex]);
      } else {
        handleNewFlatIndex(sectionIsConcerned, duerp.sections[elementSectionIndex]);
      }
    } else {
      setFlatIndex(flatIndex - 1);
      updateQuestionState(flatIndex - 1);
    }
  };

  const goToPreviousPage = () => {
    setLoading(true);
    goToPreviousSection();
    setLoading(false);
  };

  const onCloseSearch = (flatIndex?: number) => {
    setShowSearch(false);

    // If has to navigate
    if (flatIndex !== undefined) {
      if (question === emptyQuestion) setFlatIndex(flatIndex); // If it's not a question, we can navigate directly
      else setSearchElementIndex(flatIndex); // If it's a question, we need to save the question before navigating (in onForcedSaved)
    }
  };

  const onForcedSaved = () => {
    if (searchElementIndex) setFlatIndex(searchElementIndex);
    setSearchElementIndex(undefined);
  };

  const renderFormContent = () => {
    const { element, position } = flatDuerp[flatIndex];
    let sectionUuid = "";
    // DOC : section with index -2 is Duerp general information. section with index -1 is "implication des salariés".
    // these are special display only sections and are not really "part" of the generated duerp document
    if (position.currentQuestionIndex !== -1) {
      if (position.currentSubSectionIndex !== -1) {
        const elemSub = duerp.sections[position.currentSectionIndex].subSections?.[position.currentSubSectionIndex];
        sectionUuid = elemSub?.uuid ?? "";
      } else {
        sectionUuid = duerp.sections[position.currentSectionIndex].uuid;
      }

      return question === emptyQuestion ? (
        <DuerpQuestionSkeleton />
      ) : (
               <DuerpQuestion
                 question={question}
                 sectionUuid={sectionUuid}
                 setQuestion={setQuestion}
                 updateTree={fetchDuerpData}
                 goToPreviousPage={goToPreviousPage}
                 goToNextPage={goToNextPage}
                 forceSave={!!searchElementIndex}
                 onForcedSaved={onForcedSaved}
               />
             );
    }

    const updateRiskSection = (ordering: string[]): void => {
      // updates for the frontEnd the ordering with the new indexes
      const updatedDuerp = { ...duerp };
      const riskSection = updatedDuerp.sections.find((section) => section.title === "Risques Personnalisés");

      if (!riskSection?.questions) return;

      const questionsMap = riskSection.questions.reduce<Record<string, IDuerpLightQuestion>>((acc, questionRisk) => {
        acc[questionRisk.uuid] = questionRisk;
        return acc;
      }, {});

      // since goToNextPage() actually gets the next one in the list, and not
      // the ordinal, we need to duplicate the list and then use this new list
      // on questions ordered according to the user
      riskSection.questions = ordering.reduce<typeof riskSection.questions>((acc, uuid, index) => {
        const currentQuestion = questionsMap[uuid];
        if (currentQuestion) {
          const updatedQuestion: IDuerpLightQuestionWithOrdinal = {
            ...currentQuestion,
            ordinal: index + 1
          };
          acc.push(updatedQuestion);
        }
        return acc;
      }, []);

      setDuerp(updatedDuerp);
    };

    return (
      <>
        {!company && <SkeletonDuerp />}
        {company && (
          <SectionDescription
            section={element as IDuerpSection}
            sectionIndex={position.currentSectionIndex}
            updateSectionConcernedState={updateSectionConcernedState}
            company={company}
            updateTree={fetchDuerpData}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            updateRiskSection={updateRiskSection}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Stack ref={duerpRef} sx={{ height: "100%" }}>
        <Header title={`Duerp - ${duerp.title} - ${duerp.reference}`}>
          <IconButton onClick={() => setShowSearch(true)}>
            <Search color={"primary"} fontSize={"large"} />
          </IconButton>
          <AccompanimentChip accompanimentType={duerp.accompanyingStatus} />
          <ProgressChip progressType={duerp.progressStatus} />
          {!loading && <DuerpMenuOptions document={duerp} companyUuid={company.uuid} />}
        </Header>
        <Grid sx={{ height: "100%" }} container spacing={2}>
          <Grid item xs={2}>
            <DuerpStepper
              flatDuerp={flatDuerp}
              flatIndex={flatIndex}
              setFlatIndex={setFlatIndex}
              completed={completed}
              progressValue={progressValue}
            />
          </Grid>
          <Grid item xs={10} sx={{ pr: 2, my: 2, display: "flex", flexDirection: "column" }}>
            {loading || flatDuerp.length === 0 ? <SkeletonDuerp /> : renderFormContent()}
            {/* <Stack direction="row" alignItems="center" justifyContent={buttonPosition} sx={{ mt: 5 }}>
                {flatIndex !== 0 && <GenericButton onClick={() => goToPreviousPage()} text="Précédent" />}
                {flatIndex < flatDuerp.length - 1 && <GenericButton onClick={goToNextPage} text={nextButtonText()} />}
              </Stack> */}
          </Grid>
        </Grid>
      </Stack>

      <DuerpSearchDialog open={showSearch} onClose={onCloseSearch} duerpUuid={duerp.uuid} flatDuerp={flatDuerp} />
    </>
  );
}
