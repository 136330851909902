// === Import NPM
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, MenuItem, TextField, TextFieldProps } from "@mui/material";
import React, { useMemo, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

// === Import LOCAL
import { IOptionValue } from "../../../interfaces/Filter";

interface GenericTextFieldProps {
  multipleSelect?: boolean;
  optionValues?: IOptionValue[];
  register?: UseFormRegisterReturn;
  isPassword?: boolean;
}

export default function GenericTextField({
  select,
  multipleSelect,
  optionValues,
  register,
  isPassword = false,
  ...props
}: Readonly<GenericTextFieldProps & TextFieldProps>) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const renderedOptionValues = useMemo(
    () =>
      optionValues?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    [optionValues],
  );

  return (
    <TextField
      fullWidth
      select={select}
      {...(select ? { SelectProps: { multiple: multipleSelect } } : {})}
      {...props}
      type={!select ? (isPassword ? (showPassword ? "text" : "password") : "text") : undefined}
      {...register}
      {...(!select
        ? {
            InputProps: {
              endAdornment: isPassword && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={showPassword ? "hide the password" : "display the password"}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }
        : {})}
    >
      {select && renderedOptionValues}
    </TextField>
  );
}
