import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridCellEditCommitParams, GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { Dispatch, SetStateAction } from "react";
import { SPSTITableRow } from "../../../../../interfaces/Form";
import colors from "../../../../../resources/cssConstant";
import GenericDatagrid from "../../../../Generics/GenericDatagrid/GenericDatagrid";

interface ComplementaryFormSPSTITableProps {
  tableRows: SPSTITableRow[];
  setTableRows: Dispatch<SetStateAction<SPSTITableRow[]>>;
}

export default function ComplementaryFormSPSTITable({
  tableRows,
  setTableRows
}: Readonly<ComplementaryFormSPSTITableProps>) {
  const deleteRow = (id: number) => {
    let updatedRows = [...tableRows];
    updatedRows = updatedRows.filter((row) => row.id !== id);
    setTableRows(updatedRows);
  };

  const renderDeleteRow = (id: number) => [
    <GridActionsCellItem
      key={id}
      icon={
        <Tooltip arrow title="Supprimer cette ligne">
          <FontAwesomeIcon size="sm" color={colors.deleteIcon} icon={faTrash} />
        </Tooltip>
      }
      label="delete"
      onClick={() => {
        deleteRow(id);
      }}
    />
  ];

  const columnGroupingModel = [
    {
      groupId: "Suivi individuel simple",
      headerAlign: "center",
      children: [{ field: "sis_cdi" }, { field: "sis_cdd" }, { field: "sis_inter" }, { field: "sis_saison" }]
    },
    {
      groupId: "Suivi individuel renforcé",
      headerAlign: "center",
      children: [{ field: "sir_cdi" }, { field: "sir_cdd" }, { field: "sir_inter" }, { field: "sir_saison" }]
    },
    {
      groupId: "Suivi individuel adapté",
      headerAlign: "center",
      children: [{ field: "sia_cdi" }, { field: "sia_cdd" }, { field: "sia_inter" }, { field: "sia_saison" }]
    }
  ];

  const createColumn = (
    field: string,
    headerName: string,
    type: string,
    minWidth?: number
  ): GridColDef => {
    const column: GridColDef = {
      field,
      headerName,
      editable: true,
      sortable: false,
      headerAlign: "center",
      flex: 1,
      minWidth: minWidth,
      align: "center",
      type,
      valueGetter: (params) => {
        if (params.value && typeof params.value === "string") {
          return params.value.replaceAll(",", ";");
        } else {
          return params.value;
        }
      },
      valueParser: (value) => {
        if (value && typeof value === "string") {
          return value.replaceAll(",", ";");
        } else {
          return value;
        }
      }
    };

    //For numeric columns, set a custom cell editor to disable negative number.
    if (type === "number") {
      column.renderEditCell = (params: GridRenderEditCellParams) => (
        <TextField
          type="number"
          variant="standard"
          value={params.value ?? ""}
          inputProps={{ min: 0 }}
          fullWidth
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            // Prevent typing the minus sign
            if (event.key === "-") event.preventDefault();
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            params.api.setEditCellValue(
              { id: params.id, field: params.field, value: event.target.value },
              event
            );
          }}
        />
      );
    }

    return column;
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      headerAlign: "center",
      flex: 0.5,
      align: "center",
      editable: false,
      sortable: false,
      getActions: (params) => renderDeleteRow(params.row.id)
    },
    createColumn("jobs", "Postes", "string", 200),
    createColumn("sis_cdi", "CDI", "number"),
    createColumn("sis_cdd", "CDD", "number"),
    createColumn("sis_inter", "Intérimaire", "number"),
    createColumn("sis_saison", "Saisonnier", "number"),
    createColumn("sir_cdi", "CDI", "number"),
    createColumn("sir_cdd", "CDD", "number"),
    createColumn("sir_inter", "Intérimaire", "number"),
    createColumn("sir_saison", "Saisonnier", "number"),
    createColumn("sia_cdi", "CDI", "number"),
    createColumn("sia_cdd", "CDD", "number"),
    createColumn("sia_inter", "Intérimaire", "number"),
    createColumn("sia_saison", "Saisonnier", "number")
  ];

  const onCellEditCommit = (params: GridCellEditCommitParams) => {
    const rowsCopy = [...tableRows];
    const isNotNumberCell = params.field === "jobs";
    const rowIndex = rowsCopy.findIndex((row) => row.id === params.id);
    const emptyValue = isNotNumberCell ? "" : 0;
    const controlledValue = params.value !== "" ? params.value : emptyValue;
    const parsedValue = parseInt(controlledValue, 10);
    const numberValue = isNotNumberCell
                        ? controlledValue.toString()
                        : isNaN(parsedValue)
                          ? 0
                          : Math.max(parsedValue, 0);
    rowsCopy[rowIndex][params.field] = numberValue;
    setTableRows(rowsCopy);
  };

  return (
    <GenericDatagrid
      columnGroupingModel={columnGroupingModel}
      columns={columns}
      size={10}
      rowsPerPageOptions={[10]}
      rows={tableRows}
      page={0}
      rowCount={0}
      onPageChange={() => undefined}
      onPageSizeChange={() => undefined}
      onCellEditCommit={onCellEditCommit}
      hideFooter
      emptyeditCellPlaceholder="Double-clic pour modifier"
      fieldsToApplyPlaceholder={["jobs"]}
    />
  );
}
