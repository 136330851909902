import { faDownload, faFileExcel, faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { userAtom } from "../../../../atoms/Atoms";
import { DocumentFormat, Type } from "../../../../interfaces/Form";
import { Role } from "../../../../interfaces/User";
import colors from "../../../../resources/cssConstant";
import { downloadActionPlan, downloadBusinessDocument, downloadDUERP, downloadRecommendationPlan } from "../../../../utils/DownloadDocuments";
import GenericButton from "../../../Generics/buttons/GenericButton";
import GenericIconButton from "../../../Generics/buttons/GenericIconButton";
import GenericDialog from "../../../Generics/GenericDialog/GenericDialog";

interface DownloadDocumentButtonProps {
  documentType: Type;
  documentUuid: string;
  iconButton?: boolean;
  menuButton?: boolean;
  endPageButtons?: boolean;
}

export default function DownloadDocumentButton({
  documentType,
  documentUuid,
  iconButton,
  menuButton,
  endPageButtons
}: Readonly<DownloadDocumentButtonProps>) {
  const isDuerp = documentType === Type.DUERP;
  const typeLabel = isDuerp ? "DUERP et Plan d'Actions" : "la Fiche Entreprise";
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAtomValue(userAtom);

  const nbGridColumns = 2;

  const downloadDocument = async (
    downloadMethod: (uuid: string, format: DocumentFormat) => Promise<void>,
    documentFormat: DocumentFormat
  ) => {
    setLoading(true);
    await downloadMethod(documentUuid, documentFormat);
    setLoading(false);
  };

  const downloadPageContent = () => (
    <Stack spacing={5}>
      <Box>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Téléchargez votre DUERP
        </Typography>
        <Grid2 container columns={nbGridColumns}>
          <Grid2 xs={1}>
            <GenericButton
              onClick={() => downloadDocument(downloadDUERP, DocumentFormat.WORD)}
              icon={faFileWord}
              text="Format Word"
              disabled={loading}
            />
          </Grid2>
          <Grid2 xs={1}>
            <GenericButton
              onClick={() => downloadDocument(downloadDUERP, DocumentFormat.PDF)}
              icon={faFilePdf}
              text="Format PDF"
              disabled={loading}
            />
          </Grid2>
        </Grid2>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ mb: 2 }}>{`Téléchargez votre Plan d'Actions`}</Typography>
        <Grid2 container columns={nbGridColumns}>
          <Grid2 xs={1}>
            <GenericButton
              onClick={() => downloadDocument(downloadActionPlan, DocumentFormat.EXCEL)}
              icon={faFileExcel}
              text="Format Excel"
              disabled={loading}
            />
          </Grid2>
          <Grid2 xs={1}>
            <GenericButton
              onClick={() => downloadDocument(downloadActionPlan, DocumentFormat.PDF)}
              icon={faFilePdf}
              text="Format PDF"
              disabled={loading}
            />
          </Grid2>
        </Grid2>
      </Box>
      {
        (user.role === Role.ADMIN || user.role === Role.EXPERT)
        &&
          <Box>
              <Typography variant="body2" sx={{ mb: 2 }}>{`Téléchargez votre Plan de Préconisation`}</Typography>
            {/* Still uses Grid2 to display the same with the other buttons */}
              <Grid2 container columns={nbGridColumns}>
                  <Grid2 xs={1}>

                  </Grid2>
                  <Grid2 xs={1}>
                      <GenericButton
                          onClick={() => downloadDocument(downloadRecommendationPlan, DocumentFormat.PDF)}
                          icon={faFilePdf}
                          text={"Format PDF"}
                          disabled={loading}
                      />
                  </Grid2>
              </Grid2>
          </Box>
      }
    </Stack>
  );

  const renderDownloadDuerpModal = () => (
    <GenericDialog
      openDialog={openDownloadModal}
      handleClose={() => setOpenDownloadModal(false)}
      title={`Télécharger DUERP et Plan d'Actions`}
    >
      {downloadPageContent()}
    </GenericDialog>
  );

  const renderDownloadModal = () =>
    isDuerp ? (
      renderDownloadDuerpModal()
    ) : (
      <GenericDialog
        openDialog={openDownloadModal}
        handleClose={() => setOpenDownloadModal(false)}
        title={`Télécharger ${typeLabel}`}
      >
        <Stack direction="row" justifyContent="space-evenly">
          <GenericButton
            onClick={() => downloadDocument(downloadBusinessDocument, DocumentFormat.WORD)}
            icon={faFileWord}
            text="Format Word"
            disabled={loading}
          />
        </Stack>
      </GenericDialog>
    );

  const renderMenuButton = () => (
    <MenuItem onClick={() => setOpenDownloadModal(true)}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faDownload} size="xs" color={colors.primary} />
      </ListItemIcon>
      <Typography variant="body1">{`Télécharger ${typeLabel}`}</Typography>
    </MenuItem>
  );

  const renderInternalButton = () => {
    return menuButton ? renderMenuButton() : renderButtonStyle();
  };

  const renderButtonStyle = () =>
    iconButton ? (
      <GenericIconButton
        onClick={() => setOpenDownloadModal(true)}
        icon={faDownload}
        tooltip={`Télécharger ${typeLabel}`}
      />
    ) : (
      <GenericButton onClick={() => setOpenDownloadModal(true)} text={`Télécharger ${typeLabel}`} />
    );

  return (
    <>
      {renderDownloadModal()}
      {endPageButtons ? downloadPageContent() : renderInternalButton()}
    </>
  );
}
