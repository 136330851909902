import { AxiosResponse } from "axios";
import { ICompany, ICompanyCreated, ICompanyCreation, ICompanyNameFilter, ICompanyShort } from "../interfaces/Company";
import { Endpoints } from "../resources/Endpoints";
import HttpService from "./HttpService";

export default function CompanyService() {
  const getCompaniesShorts = (): Promise<AxiosResponse<ICompanyShort[]>> =>
    HttpService.fetch({ url: Endpoints.companies.base, method: "get" });

  const getCompaniesNames = (): Promise<AxiosResponse<ICompanyNameFilter[]>> =>
    HttpService.fetch({ url: Endpoints.companies.names, method: "get" });

  const getCompanyById = (uuid: string): Promise<AxiosResponse<ICompany>> =>
    HttpService.fetch({
      url: Endpoints.companies.company(uuid),
      method: "get",
    });

  const getCompanyShortById = (uuid: string): Promise<AxiosResponse<ICompanyShort>> =>
    HttpService.fetch({
      url: Endpoints.companies.companyShort(uuid),
      method: "get",
    });

  const createCompany = (companyData: ICompanyCreation): Promise<AxiosResponse<ICompanyCreated>> =>
    HttpService.fetch({ url: Endpoints.companies.base, method: "post", data: companyData });

  const updateCompany = (uuid: string, companyData: ICompanyCreation): Promise<AxiosResponse<ICompany>> =>
    HttpService.fetch({ url: Endpoints.companies.company(uuid), method: "put", data: companyData });

  const updateCompanyLogo = (uuid: string, file: File): Promise<AxiosResponse<ICompany>> => {
    const formData = new FormData();
    formData.append("logo", file);
    return HttpService.fetch({
      url: Endpoints.companies.companyLogo(uuid),
      method: "put",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const getCompanyLogo = (uuid: string): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.companies.companyLogo(uuid),
      method: "get",
      responseType: "blob",
    });

  const deleteCompany = (uuid: string): Promise<AxiosResponse<void>> =>
    HttpService.fetch({
      url: Endpoints.companies.company(uuid),
      method: "delete",
    });

  return {
    getCompanyById,
    getCompaniesShorts,
    createCompany,
    updateCompany,
    updateCompanyLogo,
    getCompanyLogo,
    getCompanyShortById,
    getCompaniesNames,
    deleteCompany,
  };
}
