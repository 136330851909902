/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
// === Import NPM
import { Stack } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { AutocompleteOption, IFilterConfigurations, IFilterInputAutoComplete, IFilterInputNoSelect, IFilterInputSelect, IFilterTypes } from "../../../interfaces/Filter";

// === Import LOCAL
import { stringifyErrorMessage } from "../../../utils/ConversionMethods";
import SearchButton from "../buttons/SearchButton";
import GenericAutoComplete from "../GenericAutoComplete/GenericAutoComplete";
import GenericTextField from "../GenericTextField/GenericTextField";

interface GenericFilterProps {
  onSubmit: (data: FieldValues) => void;
  schema: yup.ObjectSchema<ObjectShape>;
  filterInputs: IFilterConfigurations<ObjectShape>;
  onChangeForControlledAutocomplete?: (value: AutocompleteOption) => void;
}

function GenericFilter({
  onSubmit,
  schema,
  filterInputs,
  onChangeForControlledAutocomplete,
}: Readonly<GenericFilterProps>) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: schema ? yupResolver(schema) : undefined,
    mode: "onTouched",
  });

  const renderTextInput = (index: number, filterInput: IFilterInputNoSelect) => {
    const { id, name, label, defaultValue } = filterInput;
    return (
      <GenericTextField
        key={index}
        type="text"
        id={id}
        label={label}
        defaultValue={defaultValue}
        error={!!errors[name]}
        helperText={stringifyErrorMessage(errors[name])}
        register={register(name)}
      />
    );
  };

  const renderSelectInput = (index: number, filterInput: IFilterInputSelect) => {
    const { id, name, label, optionValues } = filterInput;
    return (
      <GenericTextField
        key={index}
        defaultValue={optionValues[0].value}
        label={label}
        id={id}
        select
        error={!!errors[name]}
        helperText={stringifyErrorMessage(errors[name])}
        register={register(name)}
        optionValues={optionValues}
      />
    );
  };

  const renderAutocompleteInput = (index: number, filterInput: IFilterInputAutoComplete) => {
    const { id, name, label, optionValues, defaultValue, isControlled, inputValue } = filterInput;
    return (
      <GenericAutoComplete
        key={index}
        id={id}
        name={name}
        label={label}
        options={optionValues}
        control={control}
        isControlled={isControlled}
        onChange={onChangeForControlledAutocomplete}
        {...(defaultValue !== undefined && defaultValue !== null ? { defaultValue } : {})}
        inputValue={inputValue}
      />
    );
  };

  const renderFilterInput = (inputs: IFilterConfigurations<ObjectShape>) =>
    Object.entries(inputs).map(([key, value], index) => {
      switch (value.type) {
        case IFilterTypes.SELECT:
          return renderSelectInput(index, value);
        case IFilterTypes.AUTOCOMPLETE:
          return renderAutocompleteInput(index, value);
        default:
          return renderTextInput(index, value);
      }
    });

  return (
    <Stack
      component="form"
      direction="row"
      justifyContent="center"
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
      width="100%"
    >
      {renderFilterInput(filterInputs)}
      <SearchButton />
    </Stack>
  );
}

export default GenericFilter;
