import { AxiosResponse } from "axios";
import { ICompanyShort } from "../interfaces/Company";
import { ICompanyUser, ICompanyUserUpdate } from "../interfaces/CompanyUser";
import { Endpoints } from "../resources/Endpoints";
import HttpService from "./HttpService";

export default function UserService() {
  const getAllUsers = (): Promise<AxiosResponse<ICompanyUser[]>> =>
    HttpService.fetch({ url: Endpoints.users.companyUsers.base, method: "get" });

  const getUserById = (uuid: string): Promise<AxiosResponse<ICompanyUser>> =>
    HttpService.fetch({
      url: `${Endpoints.users.companyUsers.base}/${uuid}`,
      method: "get",
    });

  const checkIsExpertUserByEmail = (email: string): Promise<AxiosResponse<boolean>> =>
    HttpService.fetch({
      url: Endpoints.users.expertUsers.checkIsExpertUserByEmail(email),
      method: "get",
    });

  const getUserCompanies = (uuid: string): Promise<AxiosResponse<ICompanyShort[]>> =>
    HttpService.fetch({
      url: Endpoints.users.companyUsers.companies(uuid),
      method: "get",
    });

  const createUser = (userData: ICompanyUserUpdate): Promise<AxiosResponse<ICompanyUser>> =>
    HttpService.fetch({ url: Endpoints.users.companyUsers.base, method: "post", data: userData });

  const updateUser = (uuid: string, userData: ICompanyUserUpdate): Promise<AxiosResponse<ICompanyUser>> =>
    HttpService.fetch({ url: `${Endpoints.users.companyUsers.base}/${uuid}`, method: "put", data: userData });

  const deleteUser = (uuid: string): Promise<AxiosResponse<any>> =>
    HttpService.fetch({ url: `${Endpoints.users.companyUsers.base}/${uuid}`, method: "delete" });

  return {
    createUser,
    getAllUsers,
    getUserById,
    getUserCompanies,
    updateUser,
    deleteUser,
    checkIsExpertUserByEmail,
  };
}
