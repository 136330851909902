import { Box, Stack, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { currentDuerpAtom, userShortAtom } from "../../../../../atoms/Atoms";
import { ICompanyShort } from "../../../../../interfaces/Company";
import { Type } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import colors from "../../../../../resources/cssConstant";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../../../resources/Routes";
import CompanyService from "../../../../../services/CompanyService";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import DownloadDocumentButton from "../../../DocumentActions/DownloadDocumentButton/DownloadDocumentButton";
import NewDocumentButton from "../../../DocumentActions/NewDocumentButton/NewDocumentButton";

interface DuerpEndPageProps {
  companyUuid: string;
  goToPreviousPage: () => void;
}

export default function DuerpEndPage({ companyUuid, goToPreviousPage }: Readonly<DuerpEndPageProps>) {
  const navigate = useNavigate();
  const user = useAtomValue(userShortAtom);
  const duerp = useAtomValue(currentDuerpAtom);
  const [companyShort, setCompanyShort] = useState<ICompanyShort>({
    collaborators: [],
    companyName: "",
    complementaryFormsNumber: 0,
    duerpsNumber: 0,
    lastComplementaryForm: null,
    lastDuerp: null,
    externalCollaborators: [],
    logoUuid: "",
    membershipNumber: "",
    membershipOrganization: "",
    uuid: "",
    updateBlocked: false,
    lastUpdated: "",
    lastUpdatedUser: ""
  });

  const fetchCompanyShort = async () => {
    const res = await CompanyService().getCompanyShortById(companyUuid);
    if (res.data) {
      setCompanyShort(res.data);
    }
  };

  useEffect(() => {
    fetchCompanyShort();
  }, []);

  const goToComplementaryForm = () => {
    const complementaryForm = companyShort.lastComplementaryForm;
    const path =
      user.role === Role.COMPANY_USER ? frontOfficeRoutes.complementary_form : backOfficeRoutes.complementary_form;
    navigate(generatePath(path, { refNumber: complementaryForm?.reference.replaceAll("/", "%2F") }), {
      state: { companyShort, uuid: complementaryForm?.uuid }
    });
  };

  const goToHomePage = () => {
    if (user.role === Role.COMPANY_USER) navigate(frontOfficeRoutes.home);
    else navigate(backOfficeRoutes.home);
  };

  const renderComplementaryFormButton = () =>
    companyShort?.lastComplementaryForm ? (
      <GenericButton
        onClick={goToComplementaryForm}
        text="Compléter le questionnaire complémentaire"
        color={colors.primary}
      />
    ) : (
      <NewDocumentButton
        buttonWithIcon={false}
        company={companyShort}
        documentType={Type.COMPLEMENTARY_FORM}
        oiras={[]}
      />
    );

  return (
    <Stack spacing={5} mb={10} width="100%">
      <Typography variant="h3">Fin du DUERP</Typography>
      <Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Complétez les informations de votre entreprise en remplissant le questionnaire complémentaire :
        </Typography>
        {renderComplementaryFormButton()}
      </Box>
      <DownloadDocumentButton documentType={Type.DUERP} documentUuid={duerp.uuid} endPageButtons />
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 5 }}>
        <GenericButton onClick={() => goToPreviousPage()} text="Précédent" />
        <GenericButton onClick={goToHomePage} text="Retour à l'espace entreprise" />
      </Stack>
    </Stack>
  );
}
