import { Skeleton, TableCell, TableRow } from "@mui/material";

export default function DuerpSearchResultsSkeleton() {
  return <>
    <TableRow><TableCell size={"small"} colSpan={2}><Skeleton /></TableCell></TableRow>
    <TableRow><TableCell size={"small"} colSpan={2}><Skeleton /></TableCell></TableRow>
    <TableRow><TableCell size={"small"} colSpan={2}><Skeleton /></TableCell></TableRow>
    <TableRow><TableCell size={"small"} colSpan={2}><Skeleton /></TableCell></TableRow>
    <TableRow><TableCell size={"small"} colSpan={2}><Skeleton /></TableCell></TableRow>
    <TableRow><TableCell size={"small"} colSpan={2}><Skeleton /></TableCell></TableRow>
    <TableRow><TableCell size={"small"} colSpan={2}><Skeleton /></TableCell></TableRow>
  </>;
}
