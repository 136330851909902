import { AxiosResponse } from "axios";
import useSWR from "swr";
import {
  IDuerpCreation, IDuerpForm, IDuerpLightQuestionWithCertified, IDuerpQuestion, IDuerpQuestionCreation, IDuerpSection, IDuerpSectionUpdate, SearchInDuerpElement
} from "../interfaces/DuerpForm";
import { DocumentFormat, IShortForm } from "../interfaces/Form";
import { Endpoints } from "../resources/Endpoints";
import { formatParams, getUseSwrKey } from "../utils/Services";
import HttpService from "./HttpService";

export interface IRiskOrdering {
  uuid: string;
  ordinal: number;
}

export default function DuerpService() {
  const getDuerpFormShorts = (uuid: string): Promise<AxiosResponse<IShortForm[]>> =>
    HttpService.fetch({
      url: Endpoints.companies.duerp(uuid),
      method: "get"
    });

  const updateRisksOrdering = (duerpUuid: string, newOrder: Record<string, number>): Promise<AxiosResponse<any>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.updateRiskOrdering(duerpUuid),
      method: "put",
      data: { newOrder: newOrder }
    });

  const archiveDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.archiveDuerp(duerpUuid),
      method: "patch"
    });

  const createDuerp = (duerpCreationDto: IDuerpCreation): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.base,
      method: "post",
      data: duerpCreationDto
    });

  const getDuerpByReference = (reference: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.treeWithRef(reference),
      method: "get"
    });
  // Return a tree

  const getDuerp = (uuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.tree(uuid),
      method: "get"
    });
  // Return a tree

  const getSearchInDuerp = (uuid: string, search: string): Promise<AxiosResponse<SearchInDuerpElement[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.searchInDuerp(uuid),
      params: formatParams({ search }),
      method: "get"
    });

  const useGetSearchInDuerp = (uuid: string, search?: string) =>
    useSWR(
      () => getUseSwrKey([uuid, search], [], Endpoints.duerpForms.searchInDuerp.name),
      ([uuid, search]) => getSearchInDuerp(uuid, search),
      { revalidateOnFocus: false }
    );

  const duplicateDuerp = (duerpUuid: string, companyUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.duplicateDuerp(duerpUuid),
      method: "post",
      data: companyUuid,
      headers: { "Content-Type": "application/json" }
    });

  const deleteDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.getDuerp(duerpUuid),
      method: "delete"
    });

  const downloadDuerp = (duerpUuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.exportDuerp(duerpUuid),
      method: "get",
      params: {
        format
      },
      responseType: "blob"
    });

  const downloadActionPlan = (duerpUuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.actionPlanDuerp(duerpUuid),
      method: "get",
      params: {
        format
      },
      responseType: "blob"
    });

  const downloadRecommendationPlan = (duerpUuid: string, format: DocumentFormat): Promise<AxiosResponse<Blob>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.recommendationPlanDuerp(duerpUuid),
      method: "get",
      params: {
        format
      },
      responseType: "blob"
    });

  const validateDuerp = (duerpUuid: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.validate(duerpUuid),
      method: "patch"
    });

  const patchGeneralComment = (duerpUuid: string, newComment: string): Promise<AxiosResponse<IDuerpForm>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.generalComment(duerpUuid),
      method: "patch",
      data: { comment: newComment }
    });

  const patchCustomRiskTitle = (
    riskUuid: string,
    newTitle: string
  ): Promise<AxiosResponse<IDuerpLightQuestionWithCertified>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.changeCustomRiskTitle(riskUuid),
      method: "patch",
      params: formatParams({ newTitle })
    });

  // ---------------------------Section---------------------------
  const getDuerpSection = (duerpUuid: string, sectionUuid: string): Promise<AxiosResponse<IDuerpSection>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseSection(duerpUuid, sectionUuid),
      method: "get"
    });
  const getDuerpSectionTree = (duerpUuid: string, sectionUuid: string): Promise<AxiosResponse<IDuerpSection[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.treeSection(duerpUuid, sectionUuid),
      method: "get"
    });

  const updateDuerpSection = (
    sectionDto: IDuerpSectionUpdate,
    duerpUuid: string,
    sectionUuid: string
  ): Promise<AxiosResponse<IDuerpSection>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseSection(duerpUuid, sectionUuid),
      method: "put",
      data: sectionDto
    });

  // ---------------------------Questions---------------------------

  const getDuerpQuestion = (
    duerpUuid: string,
    sectionUuid: string,
    questionUuid: string
  ): Promise<AxiosResponse<IDuerpQuestion>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseQuestion(duerpUuid, sectionUuid, questionUuid),
      method: "get"
    });

  const updateDuerpQuestion = (
    questionDto: IDuerpQuestion,
    duerpUuid: string,
    sectionUuid: string,
    questionUuid: string
  ): Promise<AxiosResponse<IDuerpQuestion[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseQuestion(duerpUuid, sectionUuid, questionUuid),
      method: "put",
      data: questionDto
    });

  const deleteDuerpQuestion = (
    duerpUuid: string,
    sectionUuid: string,
    questionUuid: string
  ): Promise<AxiosResponse<IDuerpQuestion[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.baseQuestion(duerpUuid, sectionUuid, questionUuid),
      method: "delete"
    });

  const postDuerpQuestion = (
    questionCreationDto: IDuerpQuestionCreation,
    duerpUuid: string,
    sectionUuid: string
  ): Promise<AxiosResponse<IDuerpLightQuestionWithCertified>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.customQuestion(duerpUuid, sectionUuid),
      method: "post",
      data: questionCreationDto
    });

  const postDuerpOiraQuestion = (
    duerpUuid: string,
    sectionUuid: string,
    oiraQuestionUuid: string
  ): Promise<AxiosResponse<IDuerpLightQuestionWithCertified>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.customQuestionOira(duerpUuid, sectionUuid, oiraQuestionUuid),
      method: "post"
    });

  const postDuerpOiraQuestionsFromSection = (
    duerpUuid: string,
    sectionUuid: string,
    oiraSectionUuid: string
  ): Promise<AxiosResponse<IDuerpLightQuestionWithCertified[]>> =>
    HttpService.fetch({
      url: Endpoints.duerpForms.customQuestionsOiraFromSection(duerpUuid, sectionUuid, oiraSectionUuid),
      method: "post"
    });

  return {
    getDuerpFormShorts,
    downloadDuerp,
    archiveDuerp,
    deleteDuerp,
    duplicateDuerp,
    createDuerp,
    downloadActionPlan,
    downloadRecommendationPlan,
    getDuerp,
    getDuerpByReference,
    getSearchInDuerp,
    useGetSearchInDuerp,
    validateDuerp,
    patchGeneralComment,
    patchCustomRiskTitle,
    getDuerpSection,
    getDuerpSectionTree,
    updateDuerpSection,
    getDuerpQuestion,
    updateDuerpQuestion,
    deleteDuerpQuestion,
    postDuerpQuestion,
    updateRisksOrdering,
    postDuerpOiraQuestion,
    postDuerpOiraQuestionsFromSection
  };
}
