import { Stack } from "@mui/material";
import { DataGrid, GridCellEditCommitParams, GridSortItem } from "@mui/x-data-grid";

import { generalRowsPerPageOptions } from "../../../resources/AppConstants";
import CustomCell from "../../Commons/ComplementaryForm/containers/ComplementaryFormSPSTITable/ComplementaryFormSPSTICell";
import LoadingSkeleton from "./LoadingSkeleton";

interface GenericDatagridProps {
  rows: any[];
  columns: any[];
  sortModel?: GridSortItem[];
  loading?: boolean;
  size: number;
  page: number;
  rowCount: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  columnGroupingModel?: any;
  rowsPerPageOptions?: number[];
  paginationMode?: "server" | "client";
  onCellEditCommit?: (params: GridCellEditCommitParams) => void;
  hideFooter?: boolean;
  emptyeditCellPlaceholder?: string;
  fieldsToApplyPlaceholder?: string[];
}

export default function GenericDatagrid({
  rows,
  columns,
  sortModel,
  loading,
  size,
  page,
  onPageChange,
  onPageSizeChange,
  rowCount,
  columnGroupingModel,
  rowsPerPageOptions = generalRowsPerPageOptions,
  onCellEditCommit,
  paginationMode = "server",
  hideFooter,
  emptyeditCellPlaceholder,
  fieldsToApplyPlaceholder
}: Readonly<GenericDatagridProps>) {
  return (
    <Stack direction="row" justifyContent="center" sx={{ mt: 4 }}>
      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableDensitySelector
        disableSelectionOnClick
        rowsPerPageOptions={rowsPerPageOptions}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        columns={columns}
        rows={rows}
        sx={{ minHeight: loading ? 600 : "auto" }}
        getRowId={(row) => (row.uuid ? row.uuid : row.id)}
        loading={loading}
        components={{
          LoadingOverlay: LoadingSkeleton,
          Cell: (props) => CustomCell({ ...props, fieldsToApplyPlaceholder, placeholder: emptyeditCellPlaceholder })
        }}
        sortModel={sortModel}
        pageSize={size}
        page={page}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationMode={paginationMode}
        rowCount={rowCount}
        onCellEditCommit={onCellEditCommit}
        hideFooter={hideFooter}
      />
    </Stack>
  );
}
