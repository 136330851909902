import { faEdit, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import colors from "../../../resources/cssConstant";
import GenericIconButton from "../buttons/GenericIconButton";

type GenericCardProps = PropsWithChildren<{
  title: string;
  icon: IconDefinition;
  editCard?: () => void;
  backgroundColor?: string;
  disabled?: boolean;
}>;

export default function GenericCard({ title, icon, editCard, children, backgroundColor = colors.white, disabled }: GenericCardProps) {
  return (
    <Card variant="outlined" sx={{ p: 2, borderRadius: 3, backgroundColor, boxShadow: 3 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        <Box>
          <FontAwesomeIcon icon={icon} size="xl" color={colors.primary} />
          <Typography variant="subQuestion" sx={{ ml: 2 }}>
            {title}
          </Typography>
        </Box>
        {editCard && (
          <GenericIconButton onClick={editCard} icon={faEdit} tooltip="Modifier ce commentaire" disabled={disabled} />
        )}
      </Stack>
      {children}
    </Card>
  );
}
