/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { allOrganizationsAtom, userAtom, userCompaniesAtom } from "../../../atoms/Atoms";
import { ICompanyNameFilter, ICompanyShort } from "../../../interfaces/Company";
import { AutocompleteOption, IFilterConfigurations, IFilterTypes, IOptionValue, IShape } from "../../../interfaces/Filter";
import { IOiraShortForm } from "../../../interfaces/OiraForm";
import { Role } from "../../../interfaces/User";
import {
  AmetraTotalCount, COMPANY_FILTER_KEY, COMPANY_NAME, generalRowsPerPageOptions, MEMBERSHIP_NUMBER, MEMBERSHIP_ORGANISATION
} from "../../../resources/AppConstants";
import { StatusCode } from "../../../resources/StatusCode";
import CompanyService from "../../../services/CompanyService";
import FilterService from "../../../services/FilterService";
import FormService from "../../../services/FormService";
import UserService from "../../../services/UserService";
import { objectFilter } from "../../../utils/ObjectFilter";
import LoadingSkeleton from "../../Generics/GenericDatagrid/LoadingSkeleton";
import GenericFilter from "../../Generics/GenericFilter/GenericFilter";
import CompanyForm from "../Companies/container/CompanyForm";
import CollapsibleCompany from "./container/CollapsibleCompany/CollapsibleCompany";

interface CompanyShape {
  id: string;
  name: string;
}

interface CompanyFilterSchema {
  company: CompanyShape;
  membershipNumber: string;
  membershipOrganization: string;
}

const companyFilterSchema = yup.object<IShape<CompanyFilterSchema>>({
  company: yup.object().nullable(),
  membershipNumber: yup.string().max(70, "Le numéro d'adhérent ne doit pas excéder 70 caractères."),
  membershipOrganization: yup.string(),
});

interface CollapsibleCompanyTableProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export default function CollapsibleCompanyTable({ openModal, setOpenModal }: CollapsibleCompanyTableProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [userCompanies, setUserCompanies] = useAtom(userCompaniesAtom);
  const [companies, setCompanies] = useState<ICompanyShort[]>([]);
  const [allCompanies, setAllCompanies] = useState<ICompanyNameFilter[]>([]);
  const [oiras, setOiras] = useState<IOiraShortForm[]>([]);
  const user = useAtomValue(userAtom);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(10);
  const [currentFilter, setCurrentFilter] = useState<IFilterConfigurations<ObjectShape> | null>(null);
  const organizations = useAtomValue(allOrganizationsAtom);
  const [controlledValue, setControlledValue] = useState<AutocompleteOption>();
  const selectedValue = useMemo(() => controlledValue, [controlledValue]);

  const filterRequest = async (filterInputs, newPage?: number) => {
    setCurrentFilter(filterInputs);
    localStorage.setItem(COMPANY_FILTER_KEY, JSON.stringify(filterInputs));
    const requestParams = objectFilter(
      filterInputs,
      (filterInput) => filterInput !== "" && filterInput !== undefined && filterInput !== "ALL",
    );
    const response = await FilterService().companyFilter(requestParams, newPage ?? page, rowsPerPage);
    if (response.status === StatusCode.OK) {
      setCompanies(response.data);
      setRowCount(+(response.headers[AmetraTotalCount] ?? response.data.length));
    }
  };

  const debouncedFilterRequest = useCallback(debounce(filterRequest, 500), []);

  const fetchOiras = async () => {
    const res = await FormService().getAllPublishedOiras();
    setOiras(res?.data ?? []);
  };

  const fetchCompaniesForEmptyFilter = async () => {
    setCurrentFilter(null);
    await filterRequest({});
  };

  const getAllCompanies = async () => {
    const res = await CompanyService().getCompaniesNames();
    if (res?.data) {
      setAllCompanies(res.data);
      setStoredValueForCompanyField(res.data);
    }
  };

  const fetchUserCompanies = async () => {
    if (user.uuid) {
      const res = await UserService().getUserCompanies(user.uuid);
      if (res?.data) {
        setUserCompanies(res.data);
        setStoredValueForCompanyField(res.data);
      }
    }
  };

  const updateUserOrAdminCompanies = async () => {
    fetchCompanies();
    if (user.role === Role.COMPANY_USER) {
      fetchUserCompanies();
    } else {
      await getAllCompanies();
    }
  };

  const fetchCompanies = () => {
    if (currentFilter) {
      filterRequest(currentFilter);
    } else {
      const companyFilter = localStorage.getItem(COMPANY_FILTER_KEY);
      if (companyFilter) {
        debouncedFilterRequest(JSON.parse(companyFilter));
      } else {
        fetchCompaniesForEmptyFilter();
      }
    }
  };

  const getFieldValueFromLocalStorage = (fieldKey: string): string => {
    const companyFilterString = localStorage.getItem(COMPANY_FILTER_KEY);
    if (companyFilterString) {
      const companyFilter = JSON.parse(companyFilterString);
      return companyFilter[fieldKey];
    }
    return "";
  };

  useEffect(() => {
    fetchOiras();
    if (user.role !== Role.COMPANY_USER) {
      getAllCompanies();
    } else {
      fetchUserCompanies();
    }
    const companyFilter = localStorage.getItem(COMPANY_FILTER_KEY);
    if (companyFilter) {
      debouncedFilterRequest(JSON.parse(companyFilter));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchCompanies();
    setLoading(false);
  }, [page, rowsPerPage]);

  const membershipOrganizationOptions: IOptionValue[] = [{ id: 1, value: "ALL", label: "Tous" }].concat(
    organizations.map((organization, index) => ({
      id: index,
      value: organization.organizationName,
      label: organization.organizationName,
    })),
  );
  const setStoredValueForCompanyField = (companies: ICompanyNameFilter[] | ICompanyShort[]): void => {
    const storedCompany = getFieldValueFromLocalStorage(COMPANY_NAME);
    const companyOption = companies.find((company) => company.companyName === storedCompany);
    if (companyOption) setControlledValue({ id: companyOption.uuid, name: companyOption.companyName });
  };

  const getStoredValueForSelectFieldForOption = (options: IOptionValue[], fieldKey: string): IOptionValue[] => {
    const storedOption = getFieldValueFromLocalStorage(fieldKey);
    if (storedOption) {
      const index = options.findIndex((option) => storedOption === option.value);
      if (index !== -1) {
        const [matchedOption] = options.splice(index, 1);
        options = [matchedOption, ...options];
      }
    }

    return options;
  };

  const companyFilter: IFilterConfigurations<CompanyFilterSchema> = {
    company: {
      id: "company",
      name: "company",
      label: "Raison sociale",
      type: IFilterTypes.AUTOCOMPLETE,
      optionValues: user.role === Role.COMPANY_USER ? userCompanies : allCompanies,
      inputValue: selectedValue ?? null,
      isControlled: true,
    },
    membershipNumber: {
      id: MEMBERSHIP_NUMBER,
      name: MEMBERSHIP_NUMBER,
      label: "Numéro d'adhérent",
      defaultValue: getFieldValueFromLocalStorage(MEMBERSHIP_NUMBER) ?? "",
      type: IFilterTypes.TEXT,
    },
    membershipOrganization: {
      id: MEMBERSHIP_ORGANISATION,
      name: MEMBERSHIP_ORGANISATION,
      label: "Service de prévention et de santé au travail",
      type: IFilterTypes.SELECT,
      optionValues: getStoredValueForSelectFieldForOption(membershipOrganizationOptions, MEMBERSHIP_ORGANISATION),
    },
  };

  const onFilterSubmit = (data: FieldValues) => {
    const filterInputs = {
      companyName: selectedValue?.name ?? "",
      membershipNumber: data.membershipNumber,
      membershipOrganization: data.membershipOrganization,
    };

    setPage(0);
    debouncedFilterRequest(filterInputs, 0);
  };

  const renderTable = () => (
    <>
      <CompanyForm setOpenModal={setOpenModal} openModal={openModal} fetchCompanies={updateUserOrAdminCompanies} />{" "}
      {/*Company create modal*/}
      <GenericFilter
        onSubmit={onFilterSubmit}
        schema={companyFilterSchema}
        filterInputs={companyFilter}
        onChangeForControlledAutocomplete={setControlledValue}
      />
      <Paper variant="outlined" sx={{ borderRadius: 5 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="5%" />
                <TableCell>Raison sociale ou dénomination</TableCell>
                <TableCell>Numéro adhérent</TableCell>
                {user.role !== Role.COMPANY_USER && (
                  <>
                    <TableCell align="center">Nombre de DUERPs</TableCell>
                    <TableCell align="center">Nombre de QCs</TableCell>
                  </>
                )}
                <TableCell width="15%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.length > 0 ? (
                companies.map((company) => (
                  <CollapsibleCompany
                    key={company.uuid}
                    shortCompany={company}
                    oiras={oiras}
                    fetchCompanies={fetchCompanies}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={user.role === Role.COMPANY_USER ? 4 : 6}>
                    <Typography variant="body2">Aucun résultat</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={generalRowsPerPageOptions}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)}
          onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
        />
      </Paper>
    </>
  );

  return loading ? <LoadingSkeleton /> : renderTable();
}
