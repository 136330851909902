import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { SearchInDuerpElement, SearchInDuerpElementType } from "../../../../../../interfaces/DuerpForm";
import DuerpService from "../../../../../../services/DuerpService";
import { INavigationElement } from "../../../../../../utils/DuerpTransformation";
import DuerpSearchResultsSkeleton from "./DuerpSearchResultsSkeleton";

interface DuerpSearchResultsProps {
  duerpUuid: string;
  search?: string;
  flatDuerp: INavigationElement[];
  onClose: (flatIndex?: number) => void;
}

export default function DuerpSearchResults({ duerpUuid, search, flatDuerp, onClose }: Readonly<DuerpSearchResultsProps>) {

  const ref = useRef<HTMLTableElement>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const { data: dataSearch, isLoading: isLoadingSearch } = DuerpService().useGetSearchInDuerp(duerpUuid, search);
  const searchResults = dataSearch?.data;

  const count = useMemo(() => searchResults?.length ?? 0, [searchResults?.length]);
  const rows = useMemo(() => searchResults?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [], [searchResults, page, rowsPerPage]);

  const getTranslatedType = useCallback((type: SearchInDuerpElementType) => {
    switch (type) {
      case SearchInDuerpElementType.SECTION:
        return "Section";
      case SearchInDuerpElementType.QUESTION:
        return "Question";
      case SearchInDuerpElementType.DESCRIPTION:
        return "Description";
      case SearchInDuerpElementType.ANSWER:
        return "Mesure";
    }
  }, [SearchInDuerpElementType]);

  if (search === undefined) return null;

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    if (ref && ref.current) ref.current.scrollIntoView();
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goToElement = (result: SearchInDuerpElement) => {
    const uuidToSearch = result.type === SearchInDuerpElementType.ANSWER ? result.parentUuid : result.uuid;
    const index = flatDuerp.findIndex((element) => element.element.uuid === uuidToSearch);
    if (index !== -1) onClose(index);
    else onClose();
  };

  return (<>
    <TableContainer>
      <Table stickyHeader ref={ref}>
        <TableHead>
          <TableRow>
            <TableCell>Contenu</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(search && (isLoadingSearch || !searchResults))
           ? <DuerpSearchResultsSkeleton />
           : rows.map((result) => (
              <TableRow key={`${result.type}|${result.uuid}`} onClick={() => goToElement(result)} hover sx={{ cursor: "pointer" }}>
                <TableCell size={"small"}>{result.title}</TableCell>
                <TableCell size={"small"}>{getTranslatedType(result.type)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 15, 20, 25]}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{ flexShrink: 0 }} />
  </>);
}
