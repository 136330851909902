import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { DocumentFormat } from "../interfaces/Form";
import { StatusCode } from "../resources/StatusCode";
import ComplementaryFormService from "../services/ComplementaryFormService";
import DuerpService from "../services/DuerpService";
import FormService from "../services/FormService";

const downloadMethod = async (method: Promise<AxiosResponse<Blob>>): Promise<void> => {
  const res = await method;
  if (res.status === StatusCode.OK) {
    const file = new Blob([res.data], { type: res.headers["content-type"] });
    saveAs(file, res.headers?.["content-disposition"]?.split("filename=")[1].slice(1, -1));
    return Promise.resolve();
  }
  return Promise.reject(new Error("Impossible de télécharger ce document pour le moment."));
};

const toastWrapper = async (method: Promise<any>) =>
  toast.promise(method, {
    pending: "Votre document est en cours de préparation...",
    success: "Votre document est prêt !",
    error: "Impossible de télécharger ce document pour le moment."
  });

export const downloadDUERP = async (documentUuid: string, format: DocumentFormat) =>
  toastWrapper(downloadMethod(DuerpService().downloadDuerp(documentUuid, format)));

export const downloadBusinessDocument = async (documentUuid: string, format: DocumentFormat) =>
  toastWrapper(downloadMethod(ComplementaryFormService().downloadBusinessDocument(documentUuid, format)));

export const downloadActionPlan = async (documentUuid: string, format: DocumentFormat) =>
  toastWrapper(downloadMethod(DuerpService().downloadActionPlan(documentUuid, format)));

export const downloadRecommendationPlan = async (documentUuid: string, format: DocumentFormat) =>
  toastWrapper(downloadMethod(DuerpService().downloadRecommendationPlan(documentUuid, format)));

export const downloadOiraForm = async (oiraFormUuid: string) =>
  toastWrapper(downloadMethod(FormService().downloadOiraForm(oiraFormUuid)));
