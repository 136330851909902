// === Import NPM
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";

// === Import LOCAL
import colors from "../../../resources/cssConstant";
import GenericButton from "../buttons/GenericButton";

type GenericDialogProps = PropsWithChildren<{
  openDialog: boolean;
  handleClose: () => void;
  title: string;
  onValid?: () => void;
  disabled?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  afterActions?: ReactNode;
}>;

export default function GenericDialog({
  openDialog,
  handleClose,
  title,
  children,
  onValid,
  disabled,
  confirmLabel = "Valider",
  cancelLabel = "Annuler",
  afterActions
}: Readonly<GenericDialogProps>) {
  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth={"md"} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      {children}
      <DialogActions sx={{ marginBottom: afterActions ? 3 : 0 }}>
        <GenericButton text={cancelLabel} onClick={handleClose} color={colors.primary} />
        {onValid && (
          <GenericButton text={confirmLabel} onClick={onValid} color={colors.mainOrange} disabled={disabled} />
        )}
      </DialogActions>
      {afterActions}
    </Dialog>
  );
}
