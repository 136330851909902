import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import colors from "../../../resources/cssConstant";

interface GenericIconButtonProps {
  icon: IconDefinition;
  tooltip: string;
  color?: string;
  onClick: (event?: any) => void;
  size?: any;
  disabled?: boolean;
  disabledColor?: string;
}

export default function GenericIconButton({
  icon,
  tooltip,
  color = colors.primary,
  onClick,
  disabled,
  size,
  disabledColor = colors.disabledIcon
}: Readonly<GenericIconButtonProps>) {
  return (
    <Tooltip arrow title={tooltip}>
      <span>
        <IconButton onClick={onClick} disabled={disabled}>
          <FontAwesomeIcon size={size ?? "sm"} color={disabled ? disabledColor : color} icon={icon} />
        </IconButton>
      </span>
    </Tooltip>
  );
}
