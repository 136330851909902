/**
 * Construct the key for the useSWR hook to fetch only if all required parameters are present
 * @param required
 * @param notRequired
 * @param name
 */
export const getUseSwrKey = (required: any[], notRequired: any[], name: string) => {
  for (const requiredKey in required) {
    if (!required[requiredKey]) return null;
  }
  return [...required, ...notRequired, name];
};

export const formatParams = (obj: { [key: string]: any }) => {
  if (!obj) return;
  const params = Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
  return new URLSearchParams(params);
};
