import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import colors from "../../../resources/cssConstant";
import { stringToDateTime } from "../../../utils/ConversionMethods";

interface GenericDatePickerProps {
  label: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  defaultValue?: string;
  control: any;
  name: string;
}

export default function GenericDatePicker({
  label,
  disabled,
  maxDate,
  minDate,
  defaultValue,
  control,
  name
}: Readonly<GenericDatePickerProps>) {
  const [date, setDate] = useState<DateTime | null>(null);
  const [typingDateError, setTypingDateError] = useState<string | null>(null);

  useEffect(() => {
    const newDate = defaultValue ? stringToDateTime(defaultValue) : null;
    setDate(newDate);
  }, [defaultValue]);

  const updateError = (newDate: DateTime) => {
    if (minDate && newDate.startOf("day") < minDate.startOf("day")) {
      setTypingDateError(`La date saisie ne peut pas être inférieure au ${minDate.toLocaleString()}.`);
      return;
    }
    if (maxDate && newDate.endOf("day") > maxDate.endOf("day")) {
      setTypingDateError(`La date saisie ne peut pas être supérieure au ${maxDate.toLocaleString()}.`);
      return;
    }
    setTypingDateError(null);
  };

  return (
    <LocalizationProvider
      localeText={{
        previousMonth: "Mois précédent",
        nextMonth: "Mois suivant",
        openPreviousView: "Ouvrir la vue précédente",
        openNextView: "Ouvrir la vue suivante",
        cancelButtonLabel: "Annuler",
        clearButtonLabel: "Effacer",
        okButtonLabel: "Valider",
        todayButtonLabel: "Aujourd'hui",
        start: "Début",
        end: "Fin"
      }}
      adapterLocale="fr-FR"
      dateAdapter={AdapterLuxon}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { ref, onBlur, value, onChange, ...field }, fieldState }) => (
          <DesktopDatePicker
            {...field}
            inputRef={ref}
            label={label}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            value={date}
            onChange={(newDate) => {
              setDate(newDate);
              onChange(newDate);
              if (newDate) {
                updateError(newDate);
              } else {
                setTypingDateError(null);
              }
            }}
            renderInput={({ inputProps, ...restParams }) => (
              <TextField
                variant="outlined"
                type="date"
                {...restParams}
                sx={{
                  svg: { color: colors.primary }
                }}
                inputProps={{
                  ...inputProps,
                  placeholder: "JJ/MM/AAAA"
                }}
                onBlur={onBlur}
                name={name}
                error={!!typingDateError || !!fieldState.error}
                helperText={typingDateError || fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
