import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRowId, GridSelectionModel, GridToolbarQuickFilter } from "@mui/x-data-grid";
import * as React from "react";
import { useMemo } from "react";
import { IDuerpFutureMeasures } from "../../interfaces/DuerpForm";

const columns: GridColDef[] = [{ field: "title", headerName: "Mesures", flex: 2 }];

interface QCActionPrioritiesProps {
  data: IDuerpFutureMeasures[];
  onChange: (newSelection: GridSelectionModel) => void;
  selectedRows: GridRowId[];
  isSelectable: boolean;
}

export default function QCActionPriorities({
  data,
  onChange,
  selectedRows,
  isSelectable,
}: Readonly<QCActionPrioritiesProps>) {
  const searchBarHeight = 64;
  const rowHeight = 60;
  const dataGridTotalHeight = 500;

  const getDatagridHeight = useMemo(() => {
    const extraRows = isSelectable && selectedRows.length !== 0 ? 2 : 1;
    const calculatedHeight = searchBarHeight + rowHeight * (data.length + extraRows);
    return calculatedHeight < dataGridTotalHeight ? calculatedHeight : dataGridTotalHeight;
  }, [isSelectable, selectedRows.length, data.length]);

  const GridToolbarQuickFilterWithPadding = () => (
    <Box sx={{ paddingX: 2, paddingY: 1 }}>
      <GridToolbarQuickFilter />
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: getDatagridHeight,
      }}
    >
      <DataGrid
        headerHeight={rowHeight}
        rowHeight={rowHeight}
        checkboxSelection={isSelectable}
        rows={data.map((measure) => ({ ...measure, id: measure.uuid }))}
        disableColumnFilter
        disableDensitySelector
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick={!isSelectable}
        columns={columns}
        hideFooter={!isSelectable || selectedRows.length === 0}
        hideFooterPagination
        hideFooterSelectedRowCount={!isSelectable}
        components={{ Toolbar: GridToolbarQuickFilterWithPadding }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        sx={{
          "& .MuiDataGrid-sortIcon": {
            color: "white",
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
            color: "white",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderRadius: "0px",
          },
        }}
        selectionModel={selectedRows}
        onSelectionModelChange={(newSelection) => {
          onChange(newSelection);
        }}
      />
    </Box>
  );
}
